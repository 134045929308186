import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsPdIconPipe } from './is-pd-icon.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [IsPdIconPipe],
  exports: [IsPdIconPipe],
})
export class IsPdIconPipeModule {}
