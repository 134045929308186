import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { SearchResult, SearchResultLink } from '@features/search';
import { NEW_ES_BASE_PATH } from '../../../config';
import { SearchResultComponent } from '../shared/search-result/search-result.component';

@Component({
  selector: 'ds-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss'],
})
export class EquipmentsComponent extends SearchResultComponent {
  @Input() equipments: SearchResult.Equipment[];
  @Input() set viewAllByDefault(value: boolean) {
    this.viewAll = value;
  }
  @Input() paldeskBasePath: string;
  @Output() itemClick = new EventEmitter<SearchResultLink>();

  constructor(
    @Optional() @Inject(NEW_ES_BASE_PATH) public _newESBasePath: string,
  ) {
    super();
  }

  emitLink(equipment: SearchResult.Equipment, index: number) {
    this.itemClick.emit({
      name:
        equipment.equipment_number +
        ' ' +
        (equipment.serial_number ? `(${equipment.serial_number})` : '') +
        (equipment.product_name ? ' / ' + equipment.product_name : ''),
      description: '',
      url:
        (this._newESBasePath || '/equipment') +
        '/' +
        equipment.equipment_number +
        '?serial_number=' +
        equipment.serial_number,
      index,
    });
  }
}
