<ds-banner type="warning" class="print-hidden" *ngIf="showBanner$ | async">
  <span>{{ 'hc_status_banner.issue_text' | translate }}</span>
  <div
    fxLayout="row"
    fxLayoutAlign=" center"
    [fxLayoutGap]="0.5 | dsSpacing"
    slot="end"
  >
    <button class="banner-button-color" mat-button (click)="hideBanner()">
      {{ 'general.close' | translate }}
    </button>
    <a
      class="banner-button-color"
      target="_blank"
      mat-button
      href="{{ statusPaldeskPageLink }}"
      >{{ 'hc_status_banner.details' | translate }}</a
    >
  </div>
</ds-banner>
