import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DsSpacingPipe } from '@design-system/cdk/spacing';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  SparepartTableEntry,
  SparepartsResult,
} from '../../../shared/models/sparepart-table-entry';
import { SparepartsShopState } from '../../../store';
import { CartActions } from '../../../store/cart';
import { SearchActions, SearchSelectors } from '../../../store/search';
import { BillOfMaterialComponent } from '../bill-of-material/bill-of-material.component';

const columns = [
  'material_number',
  'available_for_selling',
  'description',
  'bom',
  'product_assignment',
  'discount',
  'lot_size',
  'weight',
  'in_stock',
  'unit_of_mesaurement',
  'price',
  'actions',
];

@Component({
  selector: 'sp-shop-sparepart-result-table',
  templateUrl: './sparepart-result-table.component.html',
  styleUrls: ['./sparepart-result-table.component.scss'],
})
export class SparepartResultTableComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Output() changePage = new EventEmitter<{
    page: number;
    sort: string | null;
  }>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[];
  addToCartAmounts: number[];
  dataSource: MatTableDataSource<SparepartTableEntry> | null;
  sparepartResults$: Observable<SparepartsResult | null>;
  sparepartResultsLoading$: Observable<boolean>;
  resultsLength = 0;
  sortDirection: SortDirection = 'asc';

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<SparepartsShopState>,
    private readonly _viewContainerRef: ViewContainerRef,
    private _dialog: MatDialog,
  ) {
    this.sparepartResults$ = this.store.select(
      SearchSelectors.getSparepartResults,
    );
    this.sparepartResultsLoading$ = this.store.select(
      SearchSelectors.getSparepartResultsLoading,
    );
  }

  ngOnInit() {
    this.displayedColumns = columns;
    this.sparepartResults$
      .pipe(
        tap((sparepartResults) => {
          if (sparepartResults) {
            this.sparepartTableResults = sparepartResults;
            this.resultsLength = sparepartResults.total_count;
          } else this.dataSource = null;
        }),
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.changePage.emit({
        page: this.paginator.pageIndex,
        sort: this.sortDirection,
      });
    });
  }

  set sparepartTableResults(result: SparepartsResult) {
    this.dataSource = new MatTableDataSource(result.items);
  }

  changeQuantity(event: any, element: SparepartTableEntry): void {
    this.store.dispatch(
      SearchActions.ChangeSparepartQuantity({
        payload: {
          quantity: parseInt(event.target.value, 10),
          sparepartId: element.id,
        },
      }),
    );
  }

  addToCart(tableEntry: SparepartTableEntry) {
    this.store.dispatch(
      CartActions.AddToShoppingCart({
        payload: { sparepart: tableEntry },
      }),
    );
  }

  sortSpareparts(sort: Sort) {
    this.sortDirection = sort.direction;
    this.changePage.emit({
      page: this.paginator.pageIndex,
      sort: sort.direction,
    });
  }

  openBOM(sparepart: SparepartTableEntry) {
    const config = new MatDialogConfig();
    config.viewContainerRef = this._viewContainerRef;
    config.width = new DsSpacingPipe().transform(300);
    const dialogRef = this._dialog.open(BillOfMaterialComponent, {
      ...config,
      autoFocus: false,
    });
    dialogRef.componentInstance.sparepart = sparepart;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
