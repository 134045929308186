import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import {
  Cart,
  CartItem,
  ModelError,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SparepartsShopState } from '../../../store';
import { CartActions, CartSelectors } from '../../../store/cart';

const columns = [
  'material_number',
  'description',
  'product_assignment',
  'discount',
  'lot_size',
  'quantity',
  'unit_of_mesaurement',
  'price',
  'total',
  'actions',
];

@Component({
  selector: 'sp-shop-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent implements OnInit {
  quickAddForm: UntypedFormGroup;
  displayedColumns: string[];
  dataSource: MatTableDataSource<CartItem> | null;
  totalCartPrice: number;
  currency: string;
  cart$: Observable<Cart | null>;
  cartLoading$: Observable<boolean>;
  cartLoadingError$: Observable<boolean>;
  cartLoadingErrorMsg$: Observable<ModelError | null>;

  constructor(
    private store: Store<SparepartsShopState>,
    private fb: UntypedFormBuilder,
  ) {
    this.cart$ = this.store.select(CartSelectors.getCart);
    this.cartLoading$ = this.store.select(CartSelectors.getCartLoading);
    this.cartLoadingError$ = this.store.select(
      CartSelectors.getCartLoadingError,
    );
  }

  ngOnInit() {
    this.displayedColumns = columns;
    this.cart$
      .pipe(
        tap((cart) => {
          if (cart) {
            this.dataSource = new MatTableDataSource(
              cloneDeep(cart.cart_items),
            );
            this.totalCartPrice = cart.total_cart_price;
            this.currency = cart.cart_items[0]?.spare_part.currency || '';
          } else this.dataSource = null;
        }),
      )
      .subscribe();
    this.quickAddForm = this.fb.group({
      matNr: ['', [Validators.required, Validators.minLength(2)]],
      amount: ['', [Validators.required, Validators.minLength(2)]],
    });
  }

  submitQuickAdd(): void {
    this.store.dispatch(
      CartActions.QuickAddToShoppingCart({
        payload: {
          addToCartItem: {
            material_nr: this.quickAddForm.controls['matNr'].value,
            quantity: this.quickAddForm.controls['amount'].value,
          },
        },
      }),
    );
  }

  updateCartAmount(item: CartItem, amount: any) {
    this.store.dispatch(
      CartActions.UpdateCartItemQuatity({
        payload: { item: item, amount: +amount.target.value },
      }),
    );
  }

  deleteFromCart(item: CartItem) {
    this.store.dispatch(
      CartActions.DeleteFromShoppingCart({
        payload: {
          deleteFromCart: item,
        },
      }),
    );
  }

  deleteAllItems() {
    this.store.dispatch(CartActions.DeleteAllItemsFromShoppingCart());
  }
}
