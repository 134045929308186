import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { PaginatedSpareparts } from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SparepartsShopState } from '../../store';
import { CartSelectors } from '../../store/cart';
import { SearchActions, SearchSelectors } from '../../store/search';

@Component({
  selector: 'sp-shop-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
})
export class SearchPageComponent implements OnInit, OnDestroy {
  searchForm: UntypedFormGroup;
  sparepartResults$: Observable<PaginatedSpareparts | null>;
  sparepartResultsLoading$: Observable<boolean>;
  sparepartResultsLoadingError$: Observable<boolean>;
  sparepartResultsLoadingErrorMsg$: Observable<any | null>;
  sparepartType: string;
  private readonly destroy$ = new Subject<void>();
  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<SparepartsShopState>,
    private route: ActivatedRoute,
  ) {
    this.sparepartResults$ = this.store.select(
      SearchSelectors.getSparepartResults,
    );
    this.sparepartResultsLoading$ = this.store.select(
      SearchSelectors.getSparepartResultsLoading,
    );
    this.sparepartResultsLoadingError$ = this.store.select(
      SearchSelectors.getSparepartResultsLoadingError,
    );
    this.sparepartResultsLoadingErrorMsg$ = this.store.select(
      SearchSelectors.getSparepartResultsLoadingErrorMsg,
    );
    this.store
      .pipe(
        select(CartSelectors.getCurrentSparepartType),
        takeUntil(this.destroy$),
      )
      .subscribe((spType) => (this.sparepartType = spType));
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      const sparePartCodeQueryParameter = params['search_term'] || '';
      this.searchForm = this.fb.group({
        searchTerm: [
          sparePartCodeQueryParameter,
          [Validators.required, Validators.minLength(2)],
        ],
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  submitSearchForm(searchDetails?: {
    page: number;
    sort: string | null;
  }): void {
    this.store.dispatch(
      SearchActions.SearchSpareparts({
        payload: {
          searchTerm: this.searchForm.controls['searchTerm'].value,
          pageIndex: searchDetails?.page || 0,
          sparepartType: this.sparepartType,
          pageSize: 20,
        },
      }),
    );
  }
}
