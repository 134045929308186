<ng-container *ngIf="searchHistory?.length">
  <h4 class="uppercase">
    <small>{{ 'header.search.recent' | translate }}</small>
  </h4>
  <mat-action-list dense>
    <a
      class="no-underline"
      mat-list-item
      [href]="
        item.url?.indexOf('http') === -1
          ? config.paldeskBasePath + item.url
          : item.url
      "
      target="_blank"
      *ngFor="let item of searchHistory"
    >
      <div class="full-width" fxLayout fxLayoutAlign="space-between">
        <div
          fxLayout
          fxLayoutAlign="start center"
          [fxLayoutGap]="0.5 | dsSpacing"
        >
          <span>{{ item.name }}</span>
          <span>{{ item.description }}</span>
        </div>
        <button
          mat-icon-button
          (click)="$event.preventDefault(); removeResult(item)"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </a>
    <button mat-list-item (click)="clearHistory()">
      <strong>{{ 'header.search.clear-history' | translate }}</strong>
    </button>
  </mat-action-list>
</ng-container>
