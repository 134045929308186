import { Component, Input } from '@angular/core';
import { matExpansionAnimations } from '@angular/material/expansion';

@Component({
  selector: 'ds-view-toggle',
  templateUrl: './view-toggle.component.html',
  styleUrls: ['./view-toggle.component.scss'],
  animations: [matExpansionAnimations.indicatorRotate],
})
export class ViewToggleComponent {
  @Input() allVisible = false;
}
