import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsPageModule } from '@design-system/feature/page';
import { TranslateModule } from '@ngx-translate/core';
import { BillOfMaterialComponent } from './bill-of-material/bill-of-material.component';
import { SearchPageComponent } from './search-page.component';
import { SparepartResultTableComponent } from './sparepart-result-table/sparepart-result-table.component';

@NgModule({
  declarations: [
    SearchPageComponent,
    SparepartResultTableComponent,
    BillOfMaterialComponent,
  ],
  imports: [
    CommonModule,
    DsPageModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    DsSpacingModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DsPlaceholderModule,
    DsSpacingModule,
    TranslateModule,
    DsTextIndicatorModule,
    DsTableModule,
    DsTableLoadingModule,
    MatTableModule,
    CdkTableModule,
    MatCardModule,
    MatSortModule,
    FormsModule,
    MatSelectModule,
    DsLoadingModule,
    PdLetDirectiveModule,
    MatTooltipModule,
    RouterModule,
  ],
  exports: [SearchPageComponent],
})
export class SearchPageModule {}
