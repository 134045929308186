<ds-table fxFlex="1 1 auto">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="material_number"
    (matSortChange)="sortSpareparts($event)"
    [matSortDirection]="sortDirection"
    *ngIf="
      dataSource?.data?.length && (sparepartResultsLoading$ | async) === false;
      else !dataSource?.data?.length &&
      (sparepartResultsLoading$ | async) === false
        ? noResult
        : loading
    "
  >
    <!-- Material number column -->
    <ng-container matColumnDef="material_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'start_page.result_table.material_number' | translate }}
      </th>

      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="{
          'warning-ribbon':
            element.has_sap_problems || !element.available_for_selling
        }"
      >
        {{ element.material_number }}
        <ng-container *ngIf="element.succeesor_material_number">
          &nbsp; ({{ element.succeesor_material_number }})
        </ng-container>
      </td>
    </ng-container>

    <!-- available for selling column -->
    <ng-container matColumnDef="available_for_selling">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'start_page.result_table.available_for_selling' | translate }}
      </th>

      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="{
          'warning-text':
            element.has_sap_problems || !element.available_for_selling
        }"
      >
        {{
          (element.has_sap_problems || !element.available_for_selling
            ? 'start_page.result_table.no'
            : 'start_page.result_table.yes'
          ) | translate
        }}
      </td>
    </ng-container>
    <!-- description column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'start_page.result_table.short_text' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.description }}
      </td>
    </ng-container>
    <!-- bom column -->
    <ng-container matColumnDef="bom">
      <th mat-header-cell *matHeaderCellDef>BOM</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="bom" *ngIf="element.has_bom" (click)="openBOM(element)"
          >open_in_new</mat-icon
        >
      </td>
    </ng-container>
    <!-- product_assignment column -->
    <ng-container matColumnDef="product_assignment">
      <th
        mat-header-cell
        *matHeaderCellDef
        [innerHTML]="'start_page.result_table.product_assignment' | translate"
      ></th>
      <td mat-cell *matCellDef="let element">
        {{ element.product_assignment }}
        <ng-container
          *ngIf="element.product_assignment || element.sparepart_group"
          >/</ng-container
        >
        {{ element.sparepart_group }}
      </td>
    </ng-container>
    <!-- discount column -->
    <ng-container matColumnDef="discount">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'start_page.result_table.discount' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.price_group }} / {{ element.discount }}
      </td>
    </ng-container>
    <!-- lot_size column -->
    <ng-container matColumnDef="lot_size">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'start_page.result_table.lot_size' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.lot_size }}
      </td>
    </ng-container>
    <!-- weight column -->
    <ng-container matColumnDef="weight">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'start_page.result_table.weight' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.weight }} &nbsp;{{ element.weight_unit }}
      </td>
    </ng-container>
    <!-- in_stock column -->
    <ng-container matColumnDef="in_stock" stickyEnd>
      <th mat-header-cell *matHeaderCellDef>
        {{ 'start_page.result_table.amount' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.in_stock }}
      </td>
    </ng-container>
    <!-- unit_of_mesaurement column -->
    <ng-container matColumnDef="unit_of_mesaurement" stickyEnd>
      <th mat-header-cell *matHeaderCellDef>
        {{ 'start_page.result_table.unit_of_mesaurement' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.unit_of_mesaurement }}
      </td>
    </ng-container>
    <!-- price column -->
    <ng-container matColumnDef="price" stickyEnd>
      <th mat-header-cell *matHeaderCellDef>
        {{ 'start_page.result_table.price' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.price | currency: element.currency }}
      </td>
    </ng-container>

    <!-- actions column-->
    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell class="mat-action-cell" *matCellDef="let element">
        <mat-form-field class="quantity-width"
          ><input
            matInput
            data-cy="cartAmountInput"
            type="number"
            [value]="element.cart_amount"
            (blur)="changeQuantity($event, element)"
            [disabled]="
              element.has_sap_problems || !element.available_for_selling
            "
          />
        </mat-form-field>

        <button
          id="addToCartButton"
          mat-button
          data-cy="addToCartButton"
          (click)="addToCart(element)"
          [disabled]="
            element.has_sap_problems || !element.available_for_selling
          "
        >
          {{ 'start_page.result_table.add_to_cart' | translate }}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <ds-table-footer [hidden]="!dataSource || dataSource?.data?.length === 0">
    <mat-paginator
      [pageSize]="20"
      #paginator
      [length]="resultsLength"
      showFirstLastButtons
    ></mat-paginator>
  </ds-table-footer>
</ds-table>

<ng-template #noResult>
  <ds-placeholder
    type="no_data"
    [customHeadline]="'No results found'"
  ></ds-placeholder>
</ng-template>

<ng-template #loading>
  <mat-card>
    <mat-card-content>
      <ds-table-loading
        [numberOfHeaders]="9"
        [numberOfRows]="10"
      ></ds-table-loading>
    </mat-card-content>
  </mat-card>
</ng-template>
