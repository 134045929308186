import { Action, createReducer, on } from '@ngrx/store';
import {
  BomContainer,
  ModelError,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { cloneDeep } from 'lodash-es';
import {
  SparepartTableEntry,
  SparepartsResult,
} from '../../shared/models/sparepart-table-entry';
import { SearchActions } from './search.actions';

export interface SearchState {
  sparepartResults: SparepartsResult | null;
  sparepartResultsLoading: boolean;
  sparepartResultsLoadingError: boolean;
  sparepartResultsLoadingErrorMsg: ModelError | null;
  bomResults: BomContainer | null;
  bomLoading: boolean;
  bomLoadingError: boolean;
}

export const searchInitialState: SearchState = {
  sparepartResults: null,
  sparepartResultsLoading: false,
  sparepartResultsLoadingError: false,
  sparepartResultsLoadingErrorMsg: null,
  bomResults: null,
  bomLoading: false,
  bomLoadingError: false,
};

export const reducer = createReducer(
  searchInitialState,

  /**
   * Sparepart search
   */
  on(SearchActions.SearchSpareparts, (state) => ({
    ...state,
    sparepartResults: null,
    sparepartResultsLoading: true,
    sparepartResultsLoadingError: false,
    sparepartResultsLoadingErrorMsg: null,
  })),

  on(SearchActions.SearchSparepartsSuccess, (state, { payload }) => {
    const tableEntries: SparepartTableEntry[] = [];
    payload.spareparts.items.forEach((item) =>
      tableEntries.push({
        ...item,
        cart_amount: 1,
      } as SparepartTableEntry),
    );

    const sparepartResult = {
      page_index: payload.spareparts.page_index,
      total_count: payload.spareparts.total_count,
      items: tableEntries,
    };
    return {
      ...state,
      sparepartResults: sparepartResult,
      sparepartResultsLoading: false,
    };
  }),

  on(SearchActions.SearchSparepartsError, (state, { payload }) => ({
    ...state,
    sparepartResults: null,
    sparepartResultsLoading: false,
    sparepartResultsLoadingError: true,
    sparepartResultsLoadingErrorMsg: payload?.error,
  })),

  on(SearchActions.ChangeSparepartQuantity, (state, { payload }) => {
    if (state.sparepartResults) {
      const index = state.sparepartResults.items.findIndex(
        (tableDto) => tableDto.id === payload.sparepartId,
      );
      const newSpareparts = cloneDeep(state.sparepartResults);
      newSpareparts.items[index].cart_amount = payload.quantity;
      return {
        ...state,
        sparepartResults: newSpareparts,
      };
    } else return { ...state };
  }),

  /**
   * BOM
   */
  on(SearchActions.LoadBom, (state) => ({
    ...state,
    bomResults: null,
    bomLoading: true,
    bomLoadingError: false,
  })),

  on(SearchActions.LoadBomSuccess, (state, { payload }) => ({
    ...state,
    bomResults: payload.bomContainer,
    bomLoading: false,
  })),

  on(SearchActions.LoadBomError, (state) => ({
    ...state,
    bomResults: null,
    bomLoading: false,
    bomLoadingError: true,
  })),
);

export function searchReducer(state: SearchState | undefined, action: Action) {
  return reducer(state, action);
}
