<form [formGroup]="form" novalidate (ngSubmit)="startSearch()">
  <div
    fxLayout="row"
    fxLayoutGap="1rem"
    fxLayoutAlign="start baseline"
    fxLayout.lt-md="column"
  >
    <mat-form-field class="full-width">
      <input
        matInput
        formControlName="companyName"
        minlength="3"
        maxlength="35"
        placeholder="{{ 'customer.company_name' | translate }}"
        autofocus
        autocomplete="organization"
        data-cy="company_name_input"
        required
        name="companyName"
      />
      <mat-hint> {{ 'customer.min_3' | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-select
        formControlName="country"
        name="country"
        placeholder="{{ 'customer.country' | translate }}"
        required
      >
        <mat-option
          *ngFor="let country of countries"
          [value]="country.country_code"
          >{{ country.name }}</mat-option
        >
      </mat-select>
      <mat-progress-spinner
        matTextSuffix
        *ngIf="!countries"
        [mode]="'indeterminate'"
        color="accent"
        [diameter]="14"
      ></mat-progress-spinner>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input
        name="city"
        matInput
        maxlength="35"
        data-cy="prod-reg-city"
        formControlName="city"
        placeholder="{{ 'customer.city' | translate }}"
      />
    </mat-form-field>
    <mat-form-field class="full-width">
      <input
        name="postalCode"
        matInput
        maxlength="10"
        formControlName="postalCode"
        placeholder="{{ 'customer.zip' | translate }}"
      />
    </mat-form-field>
    <button
      [disabled]="!form.valid"
      mat-raised-button
      color="primary"
      type="submit"
      data-cy="customer_search_btn"
    >
      {{ 'customer.btn_search' | translate }}
    </button>
  </div>
</form>
