import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SalesOrgService } from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SalesOrgActions } from './salesorg.actions';

@Injectable()
export class SalesOrgEffects {
  loadSalesOrgsEffects$ = createEffect(() =>
    inject(Actions).pipe(
      ofType(SalesOrgActions.LoadSalesOrgs),
      switchMap(() =>
        this.salesOrgService.getBusinessPartnerSalesOrgs().pipe(
          map((salesOrgs) =>
            SalesOrgActions.LoadSalesOrgsSuccess({
              payload: { salesOrgs: salesOrgs },
            }),
          ),
          catchError((err) => of(SalesOrgActions.LoadSalesOrgsError(err))),
        ),
      ),
    ),
  );

  constructor(private salesOrgService: SalesOrgService) {}
}
