import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MasterDataService } from '@data-access/bpm-generated';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { RootActions } from './root.actions';

export interface ErrorDto {
  code: string;
  message: string;
  log_id: string;
  validations: {
    parameter: string;
    errors: string[];
  }[];
}

@Injectable()
export class RootEffects {
  private readonly actions$ = inject(Actions);

  showSuccessMessage$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.ShowSuccessMessage),
        map((action) => action.payload),
        mergeMap((payload) => this.translateService.get(payload || 'OK')),
        tap((message) =>
          this.messengerService.sendDetailMessage({
            severity: MessageSeverityType.success,
            message: message,
            targets: MessageTargetType.toast,
          }),
        ),
      ),
    { dispatch: false },
  );

  showInfoMessage$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.ShowInfoMessage),
        map((action) => action.payload),
        mergeMap((payload) => this.translateService.get(payload)),
        tap((message) =>
          this.messengerService.sendDetailMessage({
            severity: MessageSeverityType.info,
            message: message,
            targets: MessageTargetType.toast,
          }),
        ),
      ),
    { dispatch: false },
  );

  showWarningMessage$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.ShowWarningMessage),
        map((action) => action.payload),
        mergeMap((payload) => this.translateService.get(payload)),
        tap((message) =>
          this.messengerService.sendDetailMessage({
            severity: MessageSeverityType.warning,
            message: message,
            targets: MessageTargetType.toast,
          }),
        ),
      ),
    { dispatch: false },
  );

  showErrorMessage$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.ShowErrorMessage),
        map((action) => action.payload),
        // eslint-disable-next-line sonarjs/cognitive-complexity
        mergeMap((payload) => {
          if (payload instanceof HttpErrorResponse) {
            const isValidation =
              payload.status === 400 /* bad request */ ||
              payload.status === 404; /* not found */

            let payloadError: any;
            if (typeof payload.error === 'string') {
              try {
                payloadError = JSON.parse(payload.error);
              } catch (_) {
                payloadError = null;
              }
            } else {
              payloadError = payload.error;
            }
            const error: ErrorDto = payloadError || {};
            const key =
              error.code || (isValidation ? 'validation_failed' : 'error');

            return this.translateService
              .get([key, `general.error_code.${key}`])
              .pipe(
                map((messages) =>
                  Object.keys(messages).reduce((acc, k) => {
                    const value = messages[k];
                    return value !== k ? value : acc;
                  }, ''),
                ),
                tap((message) =>
                  this.messengerService.sendDetailMessage({
                    severity: isValidation
                      ? MessageSeverityType.warning
                      : MessageSeverityType.error,
                    message: message || error.message || key,
                    targets: MessageTargetType.toast,
                    detailText: error.message,
                    detailObject: error,
                    externalID: error.log_id,
                  }),
                ),
              );
          } else if (
            payload instanceof Error &&
            payload.name === 'TimeoutError'
          ) {
            return this.translateService.get('general.error_code.timeout').pipe(
              tap((message) =>
                this.messengerService.sendDetailMessage({
                  severity: MessageSeverityType.error,
                  message: message,
                  targets: MessageTargetType.toast,
                  detailText: payload.message,
                  detailObject: payload,
                  source: payload.name,
                }),
              ),
            );
          } else {
            // TODO: log to AppInsights in order to display an externalId for technical support
            if (payload && payload.toString()) {
              const translatedMessage = this.translateService.instant(
                payload.toString(),
              );
              this.messengerService.sendDetailMessage({
                severity: MessageSeverityType.error,
                message: translatedMessage,
                targets: MessageTargetType.toast,
                detailObject: payload,
              });
              return of(payload.toString());
            } else {
              return this.translateService.get('general.error_code.error').pipe(
                tap((message) =>
                  this.messengerService.sendDetailMessage({
                    severity: MessageSeverityType.error,
                    message: message,
                    targets: MessageTargetType.toast,
                    detailObject: payload,
                  }),
                ),
              );
            }
          }
        }),
      ),
    { dispatch: false },
  );

  getCountries$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.GetCountries),
      switchMap((action) =>
        this.masterDataService.bpmGetCountries('3.0', action.payload).pipe(
          map((response) => ({
            key: action.payload.toLowerCase(),
            countryList: response.countries,
          })),
        ),
      ),
      map((countries_) => RootActions.GetCountriesSuccess(countries_)),
    ),
  );

  getRegions$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.GetRegions),
      switchMap((action) =>
        this.masterDataService
          .bpmGetCountryRegions(action.country, '3.0', action.language)
          .pipe(
            map((response) =>
              RootActions.GetRegionsSuccess({
                country: action.country,
                language: action.language,
                regionList: response.country_regions,
              }),
            ),
            catchError((error) => of(RootActions.GetRegionsError(error))),
          ),
      ),
    ),
  );

  constructor(
    private readonly messengerService: MessengerService,
    private readonly translateService: TranslateService,
    private readonly masterDataService: MasterDataService,
  ) {}
}
