import { JSONSchema } from '@ngx-pwa/local-storage';

export enum SearchIdentifiers {
  Global = 'global-search',
}

export namespace SearchResult {
  export interface Equipment {
    id: number;
    equipment_number: string;
    serial_number: string;
    product_name: string;
    material_id: string;
  }

  export interface Products {
    amount: number;
    product_categories: ProductCategory[];
  }

  export interface Documents {
    amount: number;
    document_classes: DocumentClass[];
  }

  export interface Document {
    id: string;
    title: string;
    created?: string | null;
    version: string;
    document_type: string;
    document_class_code: string;
    document_class_name: string;
    from_serial_number: string;
    until_serial_number: string;
    company_id?: number | null;
    company_name: string;
    user_id?: number | null;
    user_name: string;
    url: { [key: string]: string };
    products: string[];
  }

  export interface Sparepart {
    id: string;
    code: string;
    name: string;
  }

  export interface DocumentClass {
    document_class_code: string;
    name: string;
    documents_result_number: number;
    documents_result_url: string;
    top_documents: TopDocument[];
  }

  export interface TopDocument {
    title: string;
    url: any;
  }

  export interface ProductCategory {
    id: number;
    name: string;
    products: Product[];
    code: string;
  }

  export interface Product {
    id: number;
    name: string;
    currently_produced: boolean;
    material_id: string;
    code: string;
  }
  export interface Application {
    sitecore_id: string;
    app_id: string;
    name: string;
    icon: string;
    url: string;
    url_target: string;
  }

  export interface DocumentResult {
    documents: Document[];
    page_info: PageInfo;
  }

  export interface PageInfo {
    page_number: number;
    page_size: number;
    total_items: number;
    total_pages: number;
  }
  export interface Container {
    equipments: Equipment[];
    products: Products;
    documents: Documents;
    spareparts: Sparepart[];
    applications: Application[];
    is_empty: boolean;
    search_id: string;
  }

  export interface LogData {
    searchId: string;
    country: string;
    language: string;
    userProducts: string;
    partnertype: number;
    searchTerm: string;
    searchCategory: string;
    equipmentsCount: number;
    productsCount: number;
    sparepartsCount: number;
    documentsCount: number;
    applicationsCount: number;
  }

  export interface ItemClickLogData {
    searchId: string;
    searchCategory: string;
    fromExpanded?: boolean;
  }
}

export interface SearchResultLink {
  index?: number;
  name: string;
  description: string;
  url: string;
}

export interface SearchHistory {
  results: { [key: string]: SearchResultLink[] };
}

export enum SearchScopes {
  Equipment = 'equipment',
  Product = 'product',
  Document = 'documents',
  Sparepart = 'sparepart',
  Application = 'application',
}

export const SearchHistorySchema: JSONSchema = {
  type: 'object',
  properties: {
    results: {
      type: 'object',
      properties: {
        'global-search': {
          // have to define all possible search identifiers from SearchIdentifiers enum
          type: 'array',
          items: {
            type: 'object',
            properties: {
              index: { type: 'number' },
              name: { type: 'string' },
              description: { type: 'string' },
              url: { type: 'string' },
            },
            required: ['name', 'description', 'url'],
          },
        },
      },
    },
  },
  required: ['results'],
};
