import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import {
  AddToCart,
  AddToCartResponse,
  CartService,
  ModelError,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CartActions } from './cart.actions';

@Injectable()
export class CartEffects {
  private actions$: Actions = inject(Actions);
  /**
   * Load cart
   */
  LoadCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.LoadShoppingCart),
      switchMap(() =>
        this.cartService.getCart().pipe(
          map((cart) =>
            CartActions.LoadShoppingCartSuccess({
              payload: { cart: cart },
            }),
          ),
          catchError((err) =>
            of(
              CartActions.LoadShoppingCartError({
                payload: { error: err },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Add to cart
   */
  addToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.AddToShoppingCart),
      map((action) => action.payload),
      mergeMap((payload) =>
        this.cartService
          .addItemToCartWithId({
            material_id: payload.sparepart.id,
            quantity: payload.sparepart.cart_amount,
          } as AddToCart)
          .pipe(
            mergeMap((itemAmount: AddToCartResponse) => [
              CartActions.AddToShoppingCartSuccess({
                payload: {
                  itemAmount: itemAmount.total_cart_items,
                },
              }),
              CartActions.LoadShoppingCart(),
            ]),
            catchError(() => {
              this.snackbar.queue(
                this.translateService.instant('start_page.add_to_cart.error', {
                  item: payload.sparepart.material_number,
                }),
                {
                  type: DsSnackbarType.Error,
                },
              );
              return of(
                CartActions.AddToShoppingCartError({
                  payload: { itemId: payload.sparepart.id },
                }),
              );
            }),
          ),
      ),
    ),
  );

  /**
   * Quick Add to cart
   */
  quickAddToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.QuickAddToShoppingCart),
      map((action) => action.payload),
      switchMap((payload) =>
        this.cartService
          .addItemToCartWithNr({
            material_nr: payload.addToCartItem.material_nr,
            quantity: payload.addToCartItem.quantity,
          } as AddToCart)
          .pipe(
            switchMap((itemAmount: AddToCartResponse) => [
              CartActions.QuickAddToShoppingCartSuccess({
                payload: { itemAmount: itemAmount.total_cart_items },
              }),
              CartActions.LoadShoppingCart(),
            ]),
            catchError((err: HttpErrorResponse) => {
              const dtoError: ModelError = err.error;

              const knownErrorsCodes: string[] = [
                'material_not_sellable',
                'invalid_material_nr',
              ];
              let errorCode = 'start_page.add_to_cart.';
              errorCode += knownErrorsCodes.includes(dtoError.code)
                ? dtoError.code
                : 'error';

              this.snackbar.queue(
                this.translateService.instant(errorCode, {
                  item: payload.addToCartItem.material_nr,
                }),
                {
                  type: DsSnackbarType.Error,
                },
              );

              return of(
                CartActions.QuickAddToShoppingCartError({
                  payload: { error: err.error },
                }),
              );
            }),
          ),
      ),
    ),
  );

  /**
   * Delete from cart
   */
  DeleteFromCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.DeleteFromShoppingCart),
      map((action) => action.payload),
      switchMap((payload) =>
        this.cartService.removeItemFromCart(payload.deleteFromCart.id).pipe(
          switchMap((cart) => [
            CartActions.DeleteFromShoppingCartSuccess({
              payload: { cart: cart },
            }),
          ]),
          catchError((err) => {
            this.snackbar.queue(
              this.translateService.instant(
                'shopping_cart.delete_from_cart.error',
                {
                  item: payload.deleteFromCart.spare_part.material_number,
                },
              ),
              {
                type: DsSnackbarType.Error,
              },
            );
            return of(
              CartActions.DeleteFromShoppingCartError({
                payload: { error: err },
              }),
            );
          }),
        ),
      ),
    ),
  );

  /**
   * Delete all items from cart
   */
  DeleteAllItemsFromCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.DeleteAllItemsFromShoppingCart),
      switchMap(() =>
        this.cartService.clearCart().pipe(
          switchMap((cart) => [
            CartActions.DeleteAllItemsFromShoppingCartSuccess({
              payload: { cart: cart },
            }),
          ]),
          catchError((err) => {
            this.snackbar.queue(
              this.translateService.instant(
                'shopping_cart.delete_all_from_cart.error',
              ),
              {
                type: DsSnackbarType.Error,
              },
            );
            return of(
              CartActions.DeleteAllItemsFromShoppingCartError({
                payload: { error: err },
              }),
            );
          }),
        ),
      ),
    ),
  );

  /**
   * Update cart item quantity
   */
  updateCartItemQuantity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.UpdateCartItemQuatity),
      map((action) => action.payload),
      switchMap((payload) =>
        this.cartService
          .updateCartItemQuantity(payload.item.id, payload.amount)
          .pipe(
            map((cart) =>
              CartActions.UpdateCartItemQuatitySuccess({
                payload: { cart: cart },
              }),
            ),
            catchError((err) => {
              this.snackbar.queue(
                this.translateService.instant(
                  'shopping_cart.update_cart.error',
                  {
                    item: payload.item.spare_part.material_number,
                  },
                ),
                {
                  type: DsSnackbarType.Error,
                },
              );
              return of(
                CartActions.UpdateCartItemQuatityError({
                  payload: { error: err },
                }),
              );
            }),
          ),
      ),
    ),
  );

  changeSalesOrgForCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.ChangeSalesOrgForCurrentCart),
      map((payload) => payload),
      switchMap((payload) =>
        this.cartService
          .changeCurrentSalesOrg(payload.business_partner_sales_org_id)
          .pipe(
            map((cart) => {
              this.snackbar.queue(
                this.translateService.instant(
                  'shopping_cart.change_salesorg.success',
                ),
                {
                  type: DsSnackbarType.Success,
                },
              );
              return CartActions.ChangeSalesOrgForCurrentCartSuccess({
                payload: { cart: cart },
              });
            }),
            catchError((err) => {
              this.snackbar.queue(
                this.translateService.instant(
                  'shopping_cart.changel_salesorg.error',
                ),
                {
                  type: DsSnackbarType.Error,
                },
              );
              return of(
                CartActions.ChangeSalesOrgForCurrentCartError({
                  payload: { error: err },
                }),
              );
            }),
          ),
      ),
    ),
  );

  changeSparepartShopType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.ChangeSparepartShop),
      map((payload) => payload),
      switchMap((payload) =>
        this.cartService.changeSparepartType(payload.sparepart_type).pipe(
          map((cart) => {
            this.snackbar.queue(
              this.translateService.instant(
                'shopping_cart.change_shop.success',
              ),
              {
                type: DsSnackbarType.Success,
              },
            );
            return CartActions.ChangeSparepartShopSuccess({
              payload: { cart: cart },
            });
          }),
          catchError((err) => {
            this.snackbar.queue(
              this.translateService.instant('shopping_cart.change_shop.error'),
              {
                type: DsSnackbarType.Error,
              },
            );
            return of(
              CartActions.ChangeSparepartShopError({
                payload: { error: err },
              }),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    private cartService: CartService,
    private snackbar: DsSnackbar,
    private translateService: TranslateService,
  ) {}
}
