import { Component, EventEmitter, Input, Output } from '@angular/core';
import { matExpansionAnimations } from '@angular/material/expansion';
import { UserService } from '@features/auth';
import { SearchResult, SearchResultLink } from '@features/search';
import { DocumentUrlPipe } from '../document-url.pipe';

@Component({
  selector: 'ds-document-class',
  templateUrl: './document-class.component.html',
  styleUrls: ['./document-class.component.scss'],
  animations: [
    matExpansionAnimations.bodyExpansion,
    matExpansionAnimations.indicatorRotate,
  ],
})
export class DocumentClassComponent {
  @Input() class: SearchResult.DocumentClass;
  @Input() expanded = false;
  @Input() paldeskBasePath: string;
  @Input() searchId: string;
  @Input() searchTerm = '';
  @Output() classClick = new EventEmitter<string>();
  @Output() documentClick = new EventEmitter<SearchResultLink>();

  readonly userLang;

  constructor(private userService: UserService) {
    this.userLang = this.userService.userContext.lang;
  }

  toggleClass(classCode: string) {
    this.classClick.emit(classCode);
  }

  itemClick(document: SearchResult.TopDocument, index: number) {
    this.documentClick.emit({
      index,
      name: document.title,
      description: '',
      url: new DocumentUrlPipe().transform(document.url, this.userLang),
    } as SearchResultLink);
  }
}
