<ng-container *ngIf="loading; else results">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <br />
</ng-container>
<ng-template #results>
  <ng-container *ngIf="searchError; else table">
    <p class="error-message">
      {{ 'customer.customer_search_error' | translate }}
    </p>
  </ng-container>
  <ng-template #table>
    <p
      *ngIf="customers && customersCount > customers.length"
      [innerHtml]="
        'customer.too_much_results'
          | translate
            : {
                customersCountOffset: customersCount - customers.length
              }
      "
    ></p>
    <p *ngIf="customersCount === 0">
      {{ 'customer.no_results' | translate }}
    </p>
    <div
      class="scrollable-table"
      *ngIf="customers && customers.length && !selectedUser"
    >
      <table class="mat-mdc-table">
        <thead>
          <tr>
            <th>{{ 'customer.company_name' | translate }}</th>
            <th>{{ 'customer.address' | translate }}</th>
            <th>{{ 'customer.actions' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let customer of customers; let i = index"
            [ngClass]="{ highlight: customer.is_registered_in_bpm }"
          >
            <td>
              <a (click)="showCustomer.emit(customer)">
                {{ customer.company_name }}
                <span *ngIf="customer.company_name2"
                  >({{ customer.company_name2 }})</span
                >
              </a>
              <small
                >({{ customer.customer_number | removeLeadingZeros }})</small
              >
            </td>
            <td>
              <ng-container *ngIf="customer.street_name">
                {{ customer.street_name }}
                <span *ngIf="customer.house_number"
                  >, {{ customer.house_number }}</span
                >
              </ng-container>
              <ng-container *ngIf="!customer.street_name">
                {{ customer.street }}
              </ng-container>
              <br />
              {{ customer.postal_code }}
              {{ customer.city }}
            </td>
            <td class="action-cell">
              <button
                mat-icon-button
                (click)="handleCustomer(customer)"
                matTooltip="{{ 'customer.confirm' | translate }}"
                [disabled]="
                  disableSelectionForCompanyExistingInBPM && !!customer.bpm_id
                "
              >
                <ng-container>
                  <mat-icon>forward</mat-icon>
                </ng-container>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</ng-template>
