export interface HeaderItemData<T> {
  data: T;
}

class GAClickListener {
  static navigateTo = 'navigate_to_';
  static navigateToDocument = 'navigate_to_document_';
  static navigateToDashboard = 'navigate_to_dashboard';
  static navigateToIdentityProfile = 'navigate_to_is_profile';
  static navigateToPat = 'navigate_to_pat';
  static navigateToAppHelp = 'navigate_to_app_help';
  static navigateToAppNews = 'navigate_to_app_news';
  static navigateToPaldeskSupport = 'navigate_to_paldesk_support';
  static openFreshdeskHelp = 'open_freshdesk_help';
  static openFreshdeskIdea = 'open_freshdesk_idea';
  static openFeedback = 'open_feedback';
  static openPrivacy = 'open_privacy_policy';
  static openTos = 'open_tos';
  static logout = 'logout';
  static showApps = 'show_apps';
  static showProducts = 'show_products';
  static showServiceDocuments = 'show_service_documents';
  static showHelp = 'show_help';
  static userMenu = 'user_menu';
  static openAppInNewTabToggle = 'open_app_in_new_tab_toggle';
}

class AppWrapperV2Header {
  static headerName = 'app_wrapper_v2_header';
  static clickListener = GAClickListener;
}

class AppWrapperV2Sidebar {
  static sidebarName = 'app_wrapper_v2_sidebar_mobile';
  static clickListener = GAClickListener;
}

class GAAppWrapperV2Constants {
  static header = AppWrapperV2Header;
  static sidebar = AppWrapperV2Sidebar;
}

export const gAAppWrapperV2Constants = GAAppWrapperV2Constants;
