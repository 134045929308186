import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchState } from './search.reducer';

const searchState = createFeatureSelector<SearchState>('searchModule');

export namespace SearchSelector {
  export const searchResults = createSelector(
    searchState,
    (state: SearchState) => state?.searchResult,
  );

  export const searchTerm = createSelector(
    searchState,
    (state: SearchState) => state?.searchTerm,
  );

  export const scope = createSelector(
    searchState,
    (state: SearchState) => state?.scope || '',
  );

  export const lastSearchResult = createSelector(
    searchState,
    (state: SearchState) => state?.lastSearchResult,
  );

  export const searchHistory = createSelector(
    searchState,
    (state: SearchState) => state?.searchHistory,
  );

  export const searchPending = createSelector(
    searchState,
    (state: SearchState) => state?.searchPending,
  );
}
