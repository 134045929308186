import { createAction, props } from '@ngrx/store';
import {
  AddToCart,
  Cart,
  CartItem,
  ModelError,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { SparepartTableEntry } from '../../shared/models/sparepart-table-entry';

export namespace CartActions {
  /**
   * Load cart
   */

  export const LoadShoppingCart = createAction(
    '[SparepartsShop] LOAD_SHOPPING_CART',
  );

  export const LoadShoppingCartSuccess = createAction(
    '[SparepartsShop] LOAD_SHOPPING_CART_SUCCESS',
    props<{
      payload: { cart: Cart };
    }>(),
  );

  export const LoadShoppingCartError = createAction(
    '[SparepartsShop] LOAD_SHOPPING_CART_ERROR',
    props<{
      payload: { error: ModelError };
    }>(),
  );

  /**
   * Add to cart
   */

  export const AddToShoppingCart = createAction(
    '[SparepartsShop] ADD_TO_SHOPPING_CART',
    props<{
      payload: { sparepart: SparepartTableEntry };
    }>(),
  );

  export const AddToShoppingCartSuccess = createAction(
    '[SparepartsShop] ADD_TO_SHOPPING_CART_SUCCESS',
    props<{
      payload: { itemAmount: number };
    }>(),
  );

  export const AddToShoppingCartError = createAction(
    '[SparepartsShop] ADD_TO_SHOPPING_CART_ERROR',
    props<{
      payload: { itemId: number };
    }>(),
  );

  /**
   * Quick add to shopping cart
   */

  export const QuickAddToShoppingCart = createAction(
    '[SparepartsShop] QUICK_ADD_TO_SHOPPING_CART',
    props<{
      payload: { addToCartItem: AddToCart };
    }>(),
  );

  export const QuickAddToShoppingCartSuccess = createAction(
    '[SparepartsShop] QUICK_ADD_TO_SHOPPING_CART_SUCCESS',
    props<{
      payload: { itemAmount: number };
    }>(),
  );

  export const QuickAddToShoppingCartError = createAction(
    '[SparepartsShop] QUICK_ADD_TO_SHOPPING_CART_ERROR',
    props<{
      payload: { error: ModelError };
    }>(),
  );

  /**
   * Delete from cart
   */

  export const DeleteFromShoppingCart = createAction(
    '[SparepartsShop] DELETE_FROM_SHOPPING_CART',
    props<{
      payload: { deleteFromCart: CartItem };
    }>(),
  );

  export const DeleteFromShoppingCartSuccess = createAction(
    '[SparepartsShop] DELETE_FROM_SHOPPING_CART_SUCCESS',
    props<{
      payload: { cart: Cart };
    }>(),
  );

  export const DeleteFromShoppingCartError = createAction(
    '[SparepartsShop] DELETE_FROM_SHOPPING_CART_ERROR',
    props<{
      payload: { error: ModelError };
    }>(),
  );

  /**
   * Delete all items from cart
   */

  export const DeleteAllItemsFromShoppingCart = createAction(
    '[SparepartsShop] DELETE_ALL_ITEMS_FROM_SHOPPING_CART',
  );

  export const DeleteAllItemsFromShoppingCartSuccess = createAction(
    '[SparepartsShop] DELETE_ALL_ITEMS_FROM_SHOPPING_CART_SUCCESS',
    props<{
      payload: { cart: Cart };
    }>(),
  );

  export const DeleteAllItemsFromShoppingCartError = createAction(
    '[SparepartsShop] DELETE_ALL_ITEMS_FROM_SHOPPING_CART_ERROR',
    props<{
      payload: { error: ModelError };
    }>(),
  );

  /**
   * Update Quantity
   */

  export const UpdateCartItemQuatity = createAction(
    '[SparepartsShop] UPDATE_CART_ITEM_QUANTITY',
    props<{
      payload: { item: CartItem; amount: number };
    }>(),
  );

  export const UpdateCartItemQuatitySuccess = createAction(
    '[SparepartsShop] UPDATE_CART_ITEM_QUANTITY_SUCCESS',
    props<{
      payload: { cart: Cart };
    }>(),
  );

  export const UpdateCartItemQuatityError = createAction(
    '[SparepartsShop] UPDATE_CART_ITEM_QUANTITY_ERROR',
    props<{
      payload: { error: ModelError };
    }>(),
  );

  /**
   * Change SalesOrgOfCart
   */

  export const ChangeSalesOrgForCurrentCart = createAction(
    '[SparepartsShop] CHANGE_SALESORG_FOR_CURRENT_CART',
    props<{
      business_partner_sales_org_id: number;
    }>(),
  );

  export const ChangeSalesOrgForCurrentCartSuccess = createAction(
    '[SparepartsShop] CHANGE_SALESORG_FOR_CURRENT_CART_SUCCESS',
    props<{
      payload: { cart: Cart };
    }>(),
  );

  export const ChangeSalesOrgForCurrentCartError = createAction(
    '[SparepartsShop] CHANGE_SALESORG_FOR_CURRENT_CART_ERROR',
    props<{
      payload: { error: ModelError };
    }>(),
  );

  export const ChangeSparepartShop = createAction(
    '[SparepartsShop] CHANGE_SPAREPART_SHOP',
    props<{
      sparepart_type: string;
    }>(),
  );

  export const ChangeSparepartShopSuccess = createAction(
    '[SparepartsShop] CHANGE_SPAREPART_SHOP_SUCCESS',
    props<{
      payload: { cart: Cart };
    }>(),
  );

  export const ChangeSparepartShopError = createAction(
    '[SparepartsShop] CHANGE_SPAREPART_SHOP_ERROR',
    props<{
      payload: { error: ModelError };
    }>(),
  );
}
