import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { PARTNERTYPES } from '@config';
import {
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
} from '@design-system/feature/app-wrapper';
import { PRIVACY_POLICY_LINKS, PrivacyPolicyLink } from '../../config';

import { PdLetDirectiveModule } from '@cdk/pd-let';
import { UserContext, UserService } from '@features/auth';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { HEADER_ITEM_DATA } from '../app-wrapper-v2.service';
import { HeaderItemData, gAAppWrapperV2Constants } from '../shared/model';

const DEFAULT_PRIVACY_POLICY_URL =
  'https://www.palfinger.com/privacypolicy/paldesk_en.pdf';

@Component({
  selector: 'ds-new-paldesk-user-menu',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    FlexModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    TranslateModule,
    GoogleAnalytics4DirectiveModule,
    PdLetDirectiveModule,
  ],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  public googleAnalyticsConstants = gAAppWrapperV2Constants;

  privacyPolicyLinks: PrivacyPolicyLink[] = PRIVACY_POLICY_LINKS;

  currentYear = new Date().getFullYear();
  protected readonly partnerTypes = PARTNERTYPES;

  constructor(
    public userService: UserService,
    @Inject(HEADER_ITEM_DATA)
    public headerData: HeaderItemData<UserContext>,
    @Inject(DS_APP_WRAPPER_CONFIG) private config: DsAppWrapperConfig,
  ) {}

  navigateToProfile() {
    this.userService.navigateToProfile();
  }

  logout() {
    this.userService.logout();
  }
  login() {
    this.userService.setRedirectPath(window.location.pathname);
    this.userService.login();
  }

  navigateToPat() {
    window.open(this.config.paldeskBasePath + '/pat');
  }

  openLinkTermsConditions() {
    if (this.userService.userContext?.lang === 'DE') {
      window.open(
        'https://paldesk.blob.core.windows.net/public/terms_and_conditions_extranet_de.pdf',
      );
    } else {
      window.open(
        'https://paldesk.blob.core.windows.net/public/terms_and_conditions_extranet_en.pdf',
      );
    }
  }

  openPrivacyPolicy(): void {
    const userLang: string = this.userService.userContext?.lang;
    const privacyPolicyUrl: string =
      this.privacyPolicyLinks.find(
        (link: PrivacyPolicyLink): boolean => link.language === userLang,
      )?.url || DEFAULT_PRIVACY_POLICY_URL;
    window.open(privacyPolicyUrl);
  }
}
