import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  SearchIdentifiers,
  SearchRecentService,
  SearchResult,
  SearchResultLink,
  SearchScopes,
} from '@features/search';
import { Observable } from 'rxjs';
import { debounceTime, filter, finalize, map, switchMap } from 'rxjs/operators';
import { SearchService } from '../services';

@Component({
  selector: 'ds-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @ViewChild('searchInput', { static: true })
  searchInput: any;
  scopes = SearchScopes;
  searchResult$: Observable<SearchResult.Container>;
  searchHistory$: Observable<SearchResultLink[]>;
  searchPending = false;

  readonly form = new FormGroup({
    term: new FormControl('', {
      validators: [Validators.minLength(3), Validators.required],
      nonNullable: true,
    }),
    scope: new FormControl('', { nonNullable: true }),
  });

  constructor(
    private searchService: SearchService,
    private searchRecentService: SearchRecentService,
  ) {
    this.searchResult$ = this.form.valueChanges.pipe(
      debounceTime(300),
      filter(() => this.form.getRawValue().term.length > 2),
      switchMap(() => {
        this.searchPending = true;
        const val = this.form.getRawValue();
        return this.searchService
          .search(val.term, val.scope)
          .pipe(finalize(() => (this.searchPending = false)));
      }),
      takeUntilDestroyed(),
    );

    this.searchHistory$ = this.searchRecentService
      .getHistory()
      .pipe(map((h) => (!h ? [] : h.results[SearchIdentifiers.Global])));
  }

  ngOnInit() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 200);
  }
}
