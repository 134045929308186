import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ModelError,
  OrderResponse,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { Observable } from 'rxjs';
import { SparepartsShopState } from '../../store';
import { CheckOutSelectors } from '../../store/checkout';

@Component({
  selector: 'sp-shop-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss'],
})
export class OrderDialogComponent implements OnInit {
  orderLoading$: Observable<boolean>;
  orderError$: Observable<ModelError | undefined>;
  orderResponse$: Observable<OrderResponse | null>;

  constructor(
    private store: Store<SparepartsShopState>,
    private dialogRef: MatDialogRef<OrderDialogComponent>,
    private _router: Router,
  ) {}
  ngOnInit(): void {
    this.orderLoading$ = this.store.select(CheckOutSelectors.getOrderLoading);
    this.orderError$ = this.store.select(CheckOutSelectors.getSubmitOrderError);
    this.orderResponse$ = this.store.select(CheckOutSelectors.getOrderResponse);
  }

  closeDialog() {
    this.dialogRef.close();
  }
  openOrderHistory(orderResponse: OrderResponse) {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this._router.navigate(['/order-history', orderResponse.id]);
    });
  }
}
