import { CommonModule } from '@angular/common';
import {
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Provider,
} from '@angular/core';
import {
  Angulartics2GoogleGlobalSiteTag,
  Angulartics2Module,
} from 'angulartics2';
import { Ga4Service } from './ga4.service';

export const GoogleAnalyticsMeasurementId = new InjectionToken(
  'GA_MEASUREMENT_ID',
);

@NgModule({
  imports: [
    CommonModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        clearQueryParams: true,
        clearHash: true,
      },
    }),
  ],
  exports: [Angulartics2Module],
})
export class GoogleAnalyticsModule {
  constructor(
    private angulartics: Angulartics2GoogleGlobalSiteTag,
    private ga4Service: Ga4Service,
  ) {
    ga4Service.create();
    angulartics.startTracking();
  }

  static forRoot(
    measurementId: Provider,
  ): ModuleWithProviders<GoogleAnalyticsModule> {
    return {
      ngModule: GoogleAnalyticsModule,
      providers: [measurementId],
    };
  }
}
