import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SpotlightActions } from './shared/spotlight.actions';
import { SpotlightState } from './shared/spotlight.reducer';

@Injectable({ providedIn: 'root' })
export class DsSpotlight {
  constructor(private _store: Store<SpotlightState>) {}

  resetSpotlight(id: string) {
    this._store.dispatch(SpotlightActions.SetSpotlightAsUnseen({ id }));
  }

  resetSpotlightsByTags(tags: string[]) {
    this._store.dispatch(
      SpotlightActions.SetSpotlightsAsUnseenByTags({ tags }),
    );
  }

  resetWholeSpotlight() {
    this._store.dispatch(SpotlightActions.SetAllSpotlightAsUnseen({}));
  }
}
