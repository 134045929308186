import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppsComponent } from '../apps/apps.component';
import { DsLoadingModule } from '@design-system/components/loading';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { AppShortNamePipeModule } from '@utils/app-short-name-pipe';
import {
  DsDropdownDirective,
  MenuDropdownComponent,
} from '../menu/dropdown.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { MatBadgeModule } from '@angular/material/badge';
import { IsPdIconPipeModule } from '@shared-lib/is-pd-icon-pipe';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [AppsComponent, MenuDropdownComponent, DsDropdownDirective],
  imports: [
    CommonModule,
    DsLoadingModule,
    FlexModule,
    DsSpacingModule,
    MatIconModule,
    ExtendedModule,
    MatListModule,
    TranslateModule,
    AppShortNamePipeModule,
    MatTooltipModule,
    DsEquipmentIconModule,
    MatBadgeModule,
    IsPdIconPipeModule,
    RouterLink,
    RouterLinkActive,
    MatButtonModule,
  ],
  exports: [
    AppsComponent,
    MenuDropdownComponent,
    DsDropdownDirective,
    CommonModule,
    DsLoadingModule,
    FlexModule,
    DsSpacingModule,
    MatIconModule,
    ExtendedModule,
    MatListModule,
    TranslateModule,
    AppShortNamePipeModule,
    MatTooltipModule,
    DsEquipmentIconModule,
    MatBadgeModule,
    IsPdIconPipeModule,
    RouterLink,
    RouterLinkActive,
    MatButtonModule,
  ],
})
export class SharedWrapperModule {}
