import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsPageModule } from '@design-system/feature/page';
import { SharedLibFeatSapCustomerModule } from '@features/sap-customer';
import { TranslateModule } from '@ngx-translate/core';
import {
  DayjsDateAdapter,
  MAT_DAYJS_DATE_ADAPTER_OPTIONS,
  MAT_DAYJS_DATE_FORMATS,
} from '@paldesk/shared-lib/utils/date-utils';
import { CheckOutPageComponent } from './checkout-page.component';
import { CheckOutSettingsComponent } from './checkout-settings/checkout-settings.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';

@NgModule({
  declarations: [
    CheckOutPageComponent,
    ShoppingCartComponent,
    CheckOutSettingsComponent,
  ],
  imports: [
    CommonModule,
    DsPageModule,
    MatCheckboxModule,
    MatDatepickerModule,
    SharedLibFeatSapCustomerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    DsSpacingModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DsPlaceholderModule,
    DsSpacingModule,
    TranslateModule,
    DsTextIndicatorModule,
    DsTableModule,
    DsTableLoadingModule,
    MatTableModule,
    CdkTableModule,
    MatExpansionModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    MatSelectModule,
    DsLoadingModule,
    PdLetDirectiveModule,
    MatTooltipModule,
    MatDialogModule,
  ],
  exports: [CheckOutPageComponent],
  providers: [
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_DAYJS_DATE_FORMATS,
    },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class CheckOutPageModule {}
