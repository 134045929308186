<div fxLayout="column" [fxLayoutGap]="1 | dsSpacing">
  <mat-card class="no-padding-bottom-gt-sm" [formGroup]="quickAddForm">
    <mat-card-content
      fxFlex="0 0 auto"
      fxLayout="row wrap"
      fxLayoutAlign="stretch baseline"
      [fxLayoutGap]="1 | dsSpacing"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap.lt-md="0"
    >
      <mat-form-field fxFlex="auto" fxFlex.xs="100%">
        <mat-label>{{ 'shopping_cart.material_number' | translate }}</mat-label>
        <input matInput formControlName="matNr" data-cy="matNr" />
      </mat-form-field>
      <mat-form-field fxFlex="auto" fxFlex.xs="100%">
        <mat-label>{{ 'shopping_cart.amount' | translate }}</mat-label>
        <input
          matInput
          formControlName="amount"
          data-cy="amount"
          type="number"
        />
      </mat-form-field>
      <button
        fxFlex
        id="quick_add"
        mat-raised-button
        color="accent"
        type="submit"
        data-cy="submit"
        *pdLet="this.quickAddForm?.controls as controls"
        [disabled]="
          (controls !== null && !controls['matNr']?.valid) ||
          !controls['amount']?.valid
        "
        (click)="submitQuickAdd()"
      >
        {{ 'start_page.result_table.add_to_cart' | translate }}
      </button>
    </mat-card-content>
  </mat-card>
  <div class="max-height">
    <ds-table fxFlex="1 1 auto" class="full-width">
      <table
        mat-table
        [dataSource]="dataSource"
        *ngIf="
          dataSource?.data?.length &&
            (cartLoading$ | async) === false &&
            (cartLoadingError$ | async) === false;
          else !dataSource?.data?.length &&
          (cartLoading$ | async) === false &&
          (cartLoadingError$ | async) === false
            ? noResult
            : !dataSource?.data?.length &&
                (cartLoading$ | async) === false &&
                (cartLoadingError$ | async)
              ? error
              : loading
        "
      >
        <!-- Material number column -->
        <ng-container matColumnDef="material_number">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'start_page.result_table.material_number' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" data-cy="material_number">
            {{ element.spare_part.material_number }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <strong>{{ 'start_page.result_table.total' | translate }}</strong>
          </td>
        </ng-container>

        <!-- description column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'start_page.result_table.short_text' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.spare_part.description }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- product_assignment column -->
        <ng-container matColumnDef="product_assignment">
          <th
            mat-header-cell
            *matHeaderCellDef
            [innerHTML]="
              'start_page.result_table.product_assignment' | translate
            "
          ></th>
          <td mat-cell *matCellDef="let element">
            {{ element.spare_part.product_assignment }}
            <ng-container
              *ngIf="
                element.spare_part.product_assignment ||
                element.spare_part.sparepart_group
              "
              >/</ng-container
            >
            {{ element.spare_part.sparepart_group }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- discount column -->
        <ng-container matColumnDef="discount">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'start_page.result_table.discount' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.spare_part.price_group }} /
            {{ element.spare_part.discount }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- lot_size column -->
        <ng-container matColumnDef="lot_size">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'start_page.result_table.lot_size' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.spare_part.lot_size }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- amount column -->
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'start_page.result_table.quantity' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="quantity-width"
              ><input
                matInput
                type="number"
                [(ngModel)]="element.quantity"
                (change)="updateCartAmount(element, $event)"
              />
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- unit_of_mesaurement column -->
        <ng-container matColumnDef="unit_of_mesaurement">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'start_page.result_table.unit_of_mesaurement' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.spare_part.unit_of_mesaurement }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- price column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'start_page.result_table.price' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.spare_part.price | currency: element.spare_part.currency
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- total column -->
        <ng-container matColumnDef="total" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="print-non-sticky">
            {{ 'start_page.result_table.total' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="print-non-sticky">
            {{
              element.total_item_price | currency: element.spare_part.currency
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef class="print-non-sticky">
            <strong>{{ totalCartPrice | currency: currency }}</strong>
          </td>
        </ng-container>

        <!-- actions column-->
        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="print-hidden"></th>
          <td
            mat-cell
            class="mat-action-cell print-hidden"
            *matCellDef="let element"
          >
            <button mat-button (click)="deleteFromCart(element)">
              {{ 'start_page.result_table.delete' | translate }}
            </button>
          </td>
          <td mat-footer-cell *matFooterCellDef class="print-hidden"></td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-footer-row
          *matFooterRowDef="displayedColumns; sticky: true"
        ></tr>
      </table>
      <ds-table-actions class="print-hidden">
        <button mat-button (click)="deleteAllItems()">
          <mat-icon>delete</mat-icon>
          {{ 'checkout.delete_all_items' | translate }}
        </button>
      </ds-table-actions>
    </ds-table>
  </div>
</div>

<ng-template #noResult>
  <ds-placeholder
    data-cy="no_result_placeholder"
    type="no_data"
    [customHeadline]="'No results found'"
  ></ds-placeholder>
</ng-template>

<ng-template #loading>
  <mat-card class="loading">
    <mat-card-content>
      <ds-table-loading
        [numberOfHeaders]="9"
        [numberOfRows]="10"
      ></ds-table-loading>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #error>
  <ds-placeholder type="error"> </ds-placeholder>
  <p *pdLet="cartLoadingErrorMsg$ | async as msg">{{ msg.message }}</p>
</ng-template>
