import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  OrderService,
  ShippingService,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SparepartsShopState } from '..';
import { CartActions } from '../cart';
import { CheckOutActions } from './checkout.actions';
import { CheckOutSelectors } from './checkout.selectors';

@Injectable()
export class CheckOutEffects {
  private actions$: Actions = inject(Actions);
  private store: Store<SparepartsShopState> = inject(Store);
  /**
   * Shipping conditions
   */
  loadShippingConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckOutActions.LoadShippingConditions),
      switchMap(() =>
        this.shippingService.getShippingConditions().pipe(
          map((conditions) =>
            CheckOutActions.LoadShippingConditionsSuccess({
              payload: { conditions: conditions },
            }),
          ),
          catchError(() => of(CheckOutActions.LoadShippingConditionsError())),
        ),
      ),
    ),
  );

  /**
   * Submit order
   */

  submitOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckOutActions.SubmitOrder),
      withLatestFrom(this.store.select(CheckOutSelectors.getOrderRequest)),
      switchMap(([, /**not needed */ orderRequest]) => {
        if (orderRequest) {
          return this.orderService.createCartOrder(orderRequest).pipe(
            switchMap((payload) => [
              CheckOutActions.SubmitOrderSuccess({
                payload: payload,
              }),
              CartActions.LoadShoppingCart(),
            ]),
            catchError((err: HttpErrorResponse) =>
              of(
                CheckOutActions.SubmitOrderError({
                  payload: { error: err.error },
                }),
              ),
            ),
          );
        } else {
          return of(
            CheckOutActions.SubmitOrderError({
              payload: {
                error: {
                  code: 'no_order',
                  message: 'No order',
                  validations: [],
                },
              },
            }),
          );
        }
      }),
    ),
  );

  constructor(
    private shippingService: ShippingService,
    private orderService: OrderService,
  ) {}
}
