import { createSelector } from '@ngrx/store';
import { SparepartsShopState } from '..';
import { SalesOrgState } from './salesorg.reducer';

export namespace SalesOrgSelectors {
  export const salesOrgState = (state: SparepartsShopState) => state.salesOrg;

  export const getSalesOrgsOfBusinessPartner = createSelector(
    salesOrgState,
    (state: SalesOrgState) => state.salesOrgs,
  );
  export const getSalesOrgsOfBusinessPartnerLoading = createSelector(
    salesOrgState,
    (state: SalesOrgState) => state.salesOrgsLoading,
  );
  export const getSalesOrgsOfBusinessPartnerError = createSelector(
    salesOrgState,
    (state: SalesOrgState) => state.salesOrgsLoadingError,
  );
}
