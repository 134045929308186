<mat-card [class]="type" class="banner">
  <mat-card-content
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start"
  >
    <ds-text-indicator [type]="type" [noColor]="true">
      <ng-content></ng-content>
    </ds-text-indicator>
    <div fxFlexAlign.lt-md="end">
      <ng-content select="[slot=end]"></ng-content>
    </div>
  </mat-card-content>
</mat-card>
