import { BusinessPartnerResponse } from '@data-access/bpm-generated';
import { createAction, props } from '@ngrx/store';
import {
  ModelError,
  OrderRequest,
  OrderResponse,
  Shipping,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';

export namespace CheckOutActions {
  /**
   * Shipping conditions
   */

  export const LoadShippingConditions = createAction(
    '[SparepartsShop] LOAD_SHIPPING_CONDITIONS',
  );

  export const LoadShippingConditionsSuccess = createAction(
    '[SparepartsShop] LOAD_SHIPPING_CONDITIONS_SUCCESS',
    props<{
      payload: { conditions: Shipping };
    }>(),
  );

  export const LoadShippingConditionsError = createAction(
    '[SparepartsShop] LOAD_SHIPPING_CONDITIONS_ERROR',
  );

  /**
   * Shipping info
   */
  export const SetShippingBusinessPartner = createAction(
    '[SparepartsShop] SET_SHIPPING_BusinessPartner',
    props<{
      payload: { bp: BusinessPartnerResponse };
    }>(),
  );

  /**
   * Set order request
   */
  export const SetOrderRequest = createAction(
    '[SparepartsShop] SET_ORDER_REQUEST',
    props<{
      payload: { request: OrderRequest; orderFormValid: boolean };
    }>(),
  );

  /**
   * Submit order
   */

  export const SubmitOrder = createAction('[SparepartsShop] SUBMIT_ORDER');

  export const SubmitOrderSuccess = createAction(
    '[SparepartsShop] SUBMIT_ORDER_SUCCESS',
    props<{
      payload: OrderResponse;
    }>(),
  );

  export const SubmitOrderError = createAction(
    '[SparepartsShop] SUBMIT_ORDER_ERROR',
    props<{
      payload: { error: ModelError };
    }>(),
  );
}
