import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentClassComponent } from './documents/document-class/document-class.component';
import { DocumentUrlPipe } from './documents/document-url.pipe';
import { DocumentsComponent } from './documents/documents.component';
import { EquipmentsComponent } from './equipments/equipments.component';
import { ProductCategoryComponent } from './products/product-category/product-category.component';
import { ProductsComponent } from './products/products.component';
import { SearchResultComponent } from './search-result.component';
import { ViewToggleComponent } from './shared/view-toggle/view-toggle.component';
import { SparepartsComponent } from './spareparts/spareparts.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    CdkAccordionModule,
    RouterModule,
    DsSpacingModule,
    DsTextIndicatorModule,
    FlexLayoutModule,
    TranslateModule,
    DsLoadingModule,
    PdLetDirectiveModule,
  ],
  declarations: [
    DocumentsComponent,
    EquipmentsComponent,
    ProductsComponent,
    SparepartsComponent,
    DocumentUrlPipe,
    SearchResultComponent,
    ViewToggleComponent,
    DocumentClassComponent,
    ProductCategoryComponent,
  ],
  exports: [SearchResultComponent],
})
export class SearchResultModule {}
