import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CustomerState,
  getSelectedCustomerNumer,
  selectCustomerEntities,
} from './customer.reducer';

const moduleState = createFeatureSelector<CustomerState>('customerModule');

export namespace CustomerSelectors {
  export const list = createSelector(
    moduleState,
    (state) => state.searchResult,
  );
  export const listCount = createSelector(
    moduleState,
    (state) => state.searchResultsCount,
  );
  export const filter = createSelector(moduleState, (state) => state.filter);
  export const loading = createSelector(moduleState, (state) => state.loading);
  export const searchError = createSelector(
    moduleState,
    (state) => state.searchError,
  );
  export const searchPerformed = createSelector(
    moduleState,
    (state) => state.searchPerformed,
  );
  export const selectCurrentCustomerNumber = createSelector(
    moduleState,
    getSelectedCustomerNumer,
  );

  export const customerEntities = createSelector(
    moduleState,
    selectCustomerEntities,
  );
  export const current = createSelector(
    customerEntities,
    selectCurrentCustomerNumber,
    (entities, customerNumber) =>
      customerNumber ? entities[customerNumber] : undefined,
  );
  export const byId = (id: string) =>
    createSelector(customerEntities, (entities) => {
      for (const entity in entities) {
        if (entity.includes(id)) {
          return entities[entity];
        }
      }
      return undefined;
    });
  export const created = createSelector(moduleState, (state) => state.created);
  export const updated = createSelector(moduleState, (state) => state.updated);
  export const selectedIndustries = createSelector(
    moduleState,
    (state) => state.selectedIndustries,
  );

  export const possibleDuplicates = createSelector(
    moduleState,
    (state) => state.possibleDuplicates,
  );
}
