import { createAction, props } from '@ngrx/store';
import {
  BomContainer,
  ModelError,
  PaginatedSpareparts,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';

export namespace SearchActions {
  /**
   * Sparepart search
   */

  export const SearchSpareparts = createAction(
    '[SparepartsShop] SEARCH_SPAREPARTS',
    props<{
      payload: {
        searchTerm: string;
        sparepartType: string;
        pageIndex: number;
        pageSize: number;
        sort?: string;
      };
    }>(),
  );

  export const SearchSparepartsSuccess = createAction(
    '[SparepartsShop] SEARCH_SPAREPARTS_SUCCESS',
    props<{
      payload: { spareparts: PaginatedSpareparts };
    }>(),
  );

  export const SearchSparepartsError = createAction(
    '[SparepartsShop] SEARCH_SPAREPARTS_ERROR',
    props<{
      payload: { error: ModelError };
    }>(),
  );

  /**
   * BOM
   */
  export const LoadBom = createAction(
    '[SparepartsShop] LOAD_BOM',
    props<{
      payload: { material_number: string };
    }>(),
  );

  export const LoadBomSuccess = createAction(
    '[SparepartsShop] LOAD_BOM_SUCCESS',
    props<{
      payload: { bomContainer: BomContainer };
    }>(),
  );

  export const LoadBomError = createAction(
    '[SparepartsShop] LOAD_BOM_ERROR',
    props<{
      payload: { error: ModelError };
    }>(),
  );

  export const ChangeSparepartQuantity = createAction(
    '[SparepartsShop] CHANGE_SPAREPART_QUANTITY',
    props<{
      payload: { sparepartId: number; quantity: number };
    }>(),
  );
}
