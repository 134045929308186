import { Injectable } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

export const initialState = {
  bannerVisible: false,
};

@Injectable({
  providedIn: 'root',
})
export class HcStatusBannerStore extends signalStore(
  withState(initialState),
  withMethods((state) => ({
    hideBanner: () =>
      patchState(state, {
        bannerVisible: false,
      }),
    showBanner: () => patchState(state, { bannerVisible: true }),
  })),
) {}
