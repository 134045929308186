import { Injectable } from '@angular/core';
import { CountryResponse, RegionResponse } from '@data-access/bpm-generated';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { RootActions } from './root.actions';
import { RootState } from './root.reducer';
import { RootSelectors } from './root.selectors';
import { UserService } from '@features/auth';

@Injectable({
  providedIn: 'root',
})
export class CountryFacade {
  constructor(
    private store: Store<RootState>,
    private userService: UserService,
  ) {}
  getCountries(): Observable<CountryResponse[]> {
    let langLowerCase = this.userService.userContext.lang.toLowerCase();
    return this.store.select(RootSelectors.countries).pipe(
      tap((countries) => {
        if (!(countries && countries.key === langLowerCase)) {
          langLowerCase = this.userService.userContext.lang.toLowerCase();
          this.store.dispatch(
            RootActions.GetCountries({ payload: langLowerCase }),
          );
        }
      }),
      filter((countries) => !!countries && countries.key === langLowerCase),
      map((countries) => (countries ? countries.countryList : [])),
    );
  }

  getRegions(country: string, language?: string): Observable<RegionResponse[]> {
    this.store.dispatch(RootActions.GetRegionsErrorReset());
    const langLowerCase = language ? language.toLowerCase() : 'en';
    return combineLatest([
      this.store.select(RootSelectors.regions, {
        country,
        language: langLowerCase,
      }),
      this.store.select(RootSelectors.regionsError),
    ]).pipe(
      tap(([regions, error]) => {
        if (!regions && !error) {
          this.store.dispatch(
            RootActions.GetRegions({ country, language: langLowerCase }),
          );
        }
      }),
      filter(([regions, error]) => !!(regions || error)),
      map(([regions, error]) => {
        if (error) {
          throw new Error('customer.regions_loading_error');
        } else {
          return regions ? regions.regionList : [];
        }
      }),
    );
  }
}
