import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SparepartsService } from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SearchActions } from './search.actions';

@Injectable()
export class SearchEffects {
  private actions$: Actions = inject(Actions);
  /**
   * Sparepart search
   */
  searchSpareparts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.SearchSpareparts),
      map((action) => action.payload),
      switchMap((payload) =>
        this.sparepartsService
          .getSpareparts(
            payload.searchTerm,
            payload.sparepartType,
            payload.pageIndex,
            payload.pageSize,
          )
          .pipe(
            map((spareparts) =>
              SearchActions.SearchSparepartsSuccess({
                payload: { spareparts: spareparts },
              }),
            ),
            catchError((err) => of(SearchActions.SearchSparepartsError(err))),
          ),
      ),
    ),
  );

  /**
   * BOM
   */
  loadBOM$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.LoadBom),
      map((action) => action.payload),
      switchMap((payload) =>
        this.sparepartsService.getBom(payload.material_number).pipe(
          map((bomContainer) =>
            SearchActions.LoadBomSuccess({
              payload: { bomContainer: bomContainer },
            }),
          ),
          catchError((err) => of(SearchActions.LoadBomError(err))),
        ),
      ),
    ),
  );

  constructor(private sparepartsService: SparepartsService) {}
}
