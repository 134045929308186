import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentUrl',
})
export class DocumentUrlPipe implements PipeTransform {
  transform(urls: Map<string, string>, userLang: string): string | undefined {
    urls = new Map(Object.entries(urls));
    if (userLang && urls.has(userLang.toLowerCase())) {
      return urls.get(userLang.toLowerCase());
      // fist fallback (english)
    } else if (urls.has('en')) {
      return urls.get('en');
      // second fallback (german)
    } else if (urls.has('de')) {
      return urls.get('de');
      // third fallback (take first)
    } else {
      for (const key of urls.keys()) {
        // eslint-disable-next-line no-prototype-builtins
        if (urls.has(key)) {
          return urls.get(key);
        }
      }
    }
    return undefined;
  }
}
