<ng-container *pdLet="orderLoading$ | async as orderLoading">
  <ng-container *pdLet="orderError$ | async as orderError">
    <ng-container *pdLet="orderResponse$ | async as orderResponse">
      <h1 mat-dialog-title *ngIf="orderLoading">
        {{ 'order_dialog.processing_order' | translate }}
      </h1>
      <h1
        mat-dialog-title
        *ngIf="orderLoading === false && orderError === undefined"
      >
        {{ 'order_dialog.processing_successful' | translate }}
      </h1>
      <div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="full-height">
        <div
          mat-dialog-content
          fxLayout="row"
          fxLayoutAlign="space-around center"
          class="placeholder-container"
        >
          <mat-progress-spinner
            [mode]="'indeterminate'"
            *ngIf="orderLoading"
            color="accent"
            [diameter]="100"
          >
          </mat-progress-spinner>
          <div
            *ngIf="
              orderResponse &&
              orderLoading === false &&
              orderError === undefined
            "
          >
            <ds-placeholder
              [customHeadline]="
                ('order_dialog.order' | translate) +
                ' ' +
                orderResponse.sap_order_number
              "
              type="success"
            >
            </ds-placeholder>
          </div>

          <div *ngIf="!orderLoading && orderError">
            <ds-placeholder
              type="error"
              [customHeadline]="'order_dialog.error' | translate"
            >
              {{ orderError.message }}
            </ds-placeholder>
          </div>
        </div>
      </div>
      <div
        mat-dialog-actions
        fxLayout="row"
        [fxLayoutGap]="1 | dsSpacing"
        fxLayoutAlign="end center"
        class="dialog-actions"
      >
        <button mat-button mat-dialog-close (click)="closeDialog()">
          {{ 'order_dialog.close' | translate }}
        </button>
        <button
          mat-raised-button
          color="accent"
          [disabled]="!orderResponse"
          (click)="openOrderHistory(orderResponse)"
        >
          {{ 'order_dialog.open_order_history' | translate }}
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
