import { createSelector } from '@ngrx/store';
import {
  BusinessPartnerSalesOrg,
  Cart,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { SparepartsShopState } from '..';
import { SalesOrgSelectors } from '../salesorg';
import { CartState } from './cart.reducer';

export namespace CartSelectors {
  export const cartState = (state: SparepartsShopState) => state.cart;

  /**
   * Load cart
   */
  export const getCart = createSelector(
    cartState,
    (state: CartState) => state.cart,
  );
  export const getCartLoading = createSelector(
    cartState,
    (state: CartState) => state.cartLoading,
  );
  export const getCartLoadingError = createSelector(
    cartState,
    (state: CartState) => state.cartLoadingError,
  );
  export const getCartLoadingErrorMsg = createSelector(
    cartState,
    (state: CartState) => state.cartLoadingErrorMsg,
  );
  export const getTotalCartItems = createSelector(
    cartState,
    (state: CartState) => state.totalCartItems,
  );

  export const getCurrentSalesOrg = createSelector(
    getCart,
    SalesOrgSelectors.getSalesOrgsOfBusinessPartner,
    (cart: Cart | null, salesOrgs: BusinessPartnerSalesOrg[] | null) =>
      salesOrgs?.find((x) => x.id === cart?.business_partner_sales_org_id),
  );

  export const getCurrentSparepartType = createSelector(
    getCart,
    (cart: Cart | null) => cart?.sparepart_type || 'SP',
  );
}
