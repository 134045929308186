import { Component } from '@angular/core';
import { Application, ApplicationService } from '@features/applications';
import { TranslateService } from '@ngx-translate/core';

export interface ApplicationCategory {
  name: string;
  applications: Application[];
}

@Component({
  selector: 'ds-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss'],
})
export class AppsComponent {
  applicationCategories: ApplicationCategory[];
  canToggleFavorites: boolean;

  constructor(
    private applicationsService: ApplicationService,
    private translateService: TranslateService,
  ) {
    this.applicationsService.applications.subscribe((res) => {
      if (res) {
        this.canToggleFavorites = res.favorites.length > 1;
        this.applicationCategories = [
          {
            name: this.translateService.instant('header.launcher.lb_favorites'),
            applications: res.favorites,
          },
          ...res.categories,
        ];
      }
    });
  }

  toggleFavourite(app: Application): void {
    if (app.favorite) {
      this.applicationsService.removeFavoriteApp(app.id);
    } else {
      this.applicationsService.addFavoriteApp(app);
    }
  }
}
