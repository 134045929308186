/**
 * Provides typescript nameof operator like in C#
 * @example
 * // .component.ts
 * nameof = nameofFactory<MyModel>();
 * form = new FormGroup({
 *   [nameof('myProperty')]: new FormControl()
 * });
 * // .component.html
 * <input [formControlName]="nameof('myProperty')" />
 * @see Credits to {@link https://schneidenbach.gitbooks.io/typescript-cookbook/nameof-operator.html}
 */
export function nameofFactory<T>() {
  function nameof<TName extends keyof T>(name: TName): TName {
    return name;
  }
  return nameof;
}
