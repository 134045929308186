import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchResult, SearchResultLink } from '@features/search';
import { SearchResultComponent } from '../shared/search-result/search-result.component';

@Component({
  selector: 'ds-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent extends SearchResultComponent {
  @Input() products: SearchResult.Products;
  @Input() set viewAllByDefault(value: boolean) {
    this.viewAll = value;
  }
  @Input() paldeskBasePath: string;
  @Output() itemClick = new EventEmitter<SearchResultLink>();

  expandedCategoryId: number;

  toggleCategory(categoryId: number) {
    if (this.expandedCategoryId === categoryId) {
      this.expandedCategoryId = 0;
    } else {
      this.expandedCategoryId = categoryId;
    }
  }
}
