import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import './date-locales';

export type DateInput = Date | string | number | DateTime | Dayjs;
export type UnitOfTime = dayjs.UnitType;
export type NativeDate = Dayjs;

// wrapper class for current date extension lib.
// don't use the date extension lib in any code other than this.
// If you need functionality of the extension lib that is not supported by this class
// add it here.
export class DateTime {
  get nativeDateInstance(): Dayjs {
    return this._dateInstance;
  }
  private _dateInstance: Dayjs;
  constructor(inp?: DateInput) {
    this._dateInstance = dayjs(this._getNativeInput(inp));
    dayjs.extend(duration);
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(relativeTime);
    dayjs.extend(utc);
  }

  static locale(language?: string): void {
    dayjs.locale(language);
  }

  static durationHumanized(milliseconds: number): string {
    return dayjs.duration({ milliseconds: milliseconds }).humanize();
  }

  static utc(
    config?: string | number | Dayjs | Date | undefined,
    format?: string | undefined,
  ): Dayjs {
    return dayjs.utc(config, format);
  }

  static isNativeDate(value: any): boolean {
    return dayjs.isDayjs(value);
  }

  isBetween(a?: DateInput, b?: DateInput): boolean {
    return this._dateInstance.isBetween(
      this._getNativeInput(a),
      this._getNativeInput(b),
    );
  }

  isSameOrBefore(inp?: DateInput): boolean {
    return (
      this._dateInstance.isSame(this._getNativeInput(inp)) ||
      this._dateInstance.isBefore(this._getNativeInput(inp))
    );
  }

  isSameOrAfter(inp?: DateInput, unit?: OpUnitType): boolean {
    return this._dateInstance.isSameOrAfter(this._getNativeInput(inp), unit);
  }

  fromNow(): string {
    return this._dateInstance.fromNow();
  }

  diff(b?: DateInput): number {
    return this._dateInstance.diff(this._getNativeInput(b));
  }

  set(unit: UnitOfTime, value: number): DateTime {
    this._dateInstance = this._dateInstance.set(unit, value);
    return this;
  }

  format(format?: string): string {
    return this._dateInstance.format(format);
  }

  toDate(): Date {
    return this._dateInstance.toDate();
  }

  year(): number {
    return this._dateInstance.year();
  }

  month(): number {
    return this._dateInstance.month();
  }

  day(): number {
    return this._dateInstance.day();
  }

  date(): number {
    return this._dateInstance.date();
  }

  toString(): string {
    return this._dateInstance.toString();
  }

  getFirstDayOfTheWeek(weekStartDay: number = 1): DateTime {
    const day = this._dateInstance.day();

    const diff =
      this._dateInstance.date() -
      day +
      (day === 0
        ? weekStartDay === 1
          ? -6
          : weekStartDay === 6
            ? -1
            : 0
        : weekStartDay === 1
          ? 1
          : -(7 - weekStartDay) % 7);

    if (diff < 0) {
      const month = this._dateInstance.month();
      const year = this._dateInstance.year();
      const lastDayInMonth = new DateTime()
        .set('year', year)
        .set('month', month)
        .set('date', 0)
        .date();
      return new DateTime(this._dateInstance)
        .set('year', year)
        .set('month', month - 1)
        .set('date', lastDayInMonth - Math.abs(diff));
    } else {
      return new DateTime(this._dateInstance).set('date', diff);
    }
  }

  private _getNativeInput(inp?: DateInput): Dayjs | Date {
    if (!inp) return new Date();

    if (Object.prototype.hasOwnProperty.call(inp, '_dateInstance'))
      return (<DateTime>inp)._dateInstance;

    return <Dayjs>inp;
  }
}
