import { CountryResponse, RegionResponse } from '@data-access/bpm-generated';
import { Action, createReducer, on } from '@ngrx/store';
import { RootActions } from './root.actions';

export interface RegionList {
  language: string;
  country: string;
  regionList: RegionResponse[];
}

export enum BannerEnabledState {
  NONE = 'NONE',
  STATUS = 'STATUS',
  SHUTDOWN = 'SHUTDOWN',
  BOTH = 'BOTH',
}

export interface RootState {
  showLoadingSpinner: boolean;
  loadingSpinnerValue?: number;
  countries?: {
    key: string;
    countryList: CountryResponse[];
  };
  loadingCountries: boolean;
  regions: RegionList[];
  regionsError: any;
  bannerState: BannerEnabledState;
}
const initialState: RootState = {
  showLoadingSpinner: false,
  countries: undefined,
  loadingCountries: false,
  regions: [],
  regionsError: undefined,
  bannerState: BannerEnabledState.NONE,
};

export const reducer = createReducer(
  initialState,
  on(RootActions.ShowLoadingSpinner, (state) => ({
    ...state,
    showLoadingSpinner: true,
    loadingSpinnerValue: undefined,
  })),

  on(RootActions.UpdateLoadingSpinner, (state, { payload }) => ({
    ...state,
    loadingSpinnerValue: payload,
  })),

  on(RootActions.HideLoadingSpinner, (state) => ({
    ...state,
    showLoadingSpinner: false,
    loadingSpinnerValue: undefined,
  })),

  on(RootActions.GetCountries, (state) => ({
    ...state,
    loadingCountries: true,
  })),

  on(RootActions.GetCountriesSuccess, (state, payload) => ({
    ...state,
    countries: payload,
    loadingCountries: false,
  })),

  on(RootActions.GetRegionsSuccess, (state, payload) => ({
    ...state,
    regions: [...state.regions, payload],
  })),

  on(RootActions.GetRegionsError, (state, payload) => ({
    ...state,
    regionsError: payload,
  })),

  on(RootActions.GetRegionsErrorReset, (state) => ({
    ...state,
    regionsError: undefined,
  })),

  on(RootActions.SetBannerState, (state, payload) => ({
    ...state,
    bannerState: payload.payload,
  })),
);

export function rootReducer(state: RootState | undefined, action: Action) {
  return reducer(state, action);
}
