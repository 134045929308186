import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppWrapperService {
  drawerState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  drawerObservable = this.drawerState.asObservable();

  drawerClose() {
    this.drawerState.next(false);
  }

  drawerOpen() {
    this.drawerState.next(true);
  }
}
