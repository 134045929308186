import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPdIcon',
})
export class IsPdIconPipe implements PipeTransform {
  transform(icon: string): boolean {
    return !!(icon && icon.indexOf('pd-') > -1);
  }
}
