import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppShortNamePipe } from './app-short-name.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [AppShortNamePipe],
  exports: [AppShortNamePipe],
})
export class AppShortNamePipeModule {}
