<mat-drawer-container>
  <mat-drawer #mobileDrawer [mode]="'over'">
    <ng-container *pdLet="isHelpPageActive$ | async as isHelpPageActive">
      <ds-sidebar-mobile
        [visibleMenuItems]="visibleMenuItems"
        [appIcon]="menu?.titleIcon"
        [drawer]="mobileDrawer"
        [menu]="isHelpPageActive ? helpMenu : menu"
        [appNameOverride]="appNameOverride"
      />
    </ng-container>
  </mat-drawer>

  <mat-drawer-content
    [gaCategoryGroup]="googleAnalyticsConstants.header.headerName"
  >
    <mat-drawer-container class="mat-typography">
      <mat-drawer #drawer [autoFocus]="false">
        <div class="drawer-container padding full-height">
          <div
            fxFlex="1 1 0"
            class="full-width"
            *pdLet="isHelpPageActive$ | async as isHelpPageActive"
          >
            <ds-menu-v2
              *ngIf="(menu || isHelpPageActive) && !drawerPortal; else outlet"
              [applicationMenu]="isHelpPageActive ? helpMenu : menu"
              [menuLoadingCount]="
                isHelpPageActive
                  ? (isHelpMenuLoading$ | async)
                    ? 5
                    : 0
                  : menuLoadingCount
              "
              (helpMenuBackNavigation)="getHelpMenu()"
              fxHide.gt-xs
              data-cy="ds-menu-mobile"
            />
            <ng-template #outlet [cdkPortalOutlet]="drawerPortal" />
          </div>
          <button
            fxHide.xs
            mat-icon-button
            (click)="drawer.close()"
            data-cy="close-drawer-button"
            class="drawer-button"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
      </mat-drawer>
      <mat-drawer-content>
        <div
          *pdLet="isHelpPageActive$ | async as isHelpPageActive"
          fxLayout
          class="full-height"
          [ngClass]="{ menu: !!menu || isHelpPageActive }"
        >
          <div
            fxFlex="1 1 auto"
            class="relative-position content-wrapper"
            *pdLet="userService.isAuthorized$ | async as isAuthorized"
          >
            <div
              [ngClass]="{
                'authorized-not-end-customer':
                  userService.userContext?.partnertype !== 5 && isAuthorized,
                'unauthorized-or-end-customer':
                  userService.userContext?.partnertype === 5 || !isAuthorized
              }"
              class="w-100 background-primary sticky-header"
              data-cy="header"
              fxLayout="row"
            >
              @if (
                !isMobileSearchActive ||
                media.isActive('gt-md') ||
                (userService.userContext?.partnertype === 5 && isAuthorized)
              ) {
                <div
                  class="header-wrapper full-width"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  [fxLayoutGap.lt-sm]="0.5 | dsSpacing"
                  [fxLayoutGap]="1.5 | dsSpacing"
                >
                  <div
                    fxLayout="column"
                    fxLayoutAlign="space-between center"
                    fxHide.lg
                    fxHide.xl
                    [fxHide.gt-md]="
                      userService.userContext?.partnertype !== 5 && isAuthorized
                    "
                    [fxHide.gt-sm]="
                      userService.userContext?.partnertype === 5 ||
                      !isAuthorized
                    "
                    class="cursor-pointer menu-hover"
                    (click)="mobileDrawer.toggle()"
                  >
                    <mat-icon
                      class="mobile-header-menu-icon"
                      data-cy="mobile-header-menu-icon"
                      >reorder
                    </mat-icon>
                    <div>{{ 'navigation.menu' | translate }}</div>
                  </div>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    [fxLayoutGap]="1.5 | dsSpacing"
                  >
                    <h2
                      [fxHide.lt-lg]="
                        userService.userContext?.partnertype !== 5 &&
                        isAuthorized
                      "
                      fxHide.lt-md
                      data-cy="paldesk-heading"
                      class="default margin-bottom-0 cursor-pointer"
                      (click)="navigateToDashboard()"
                      [gaClickListener]="
                        googleAnalyticsConstants.header.clickListener
                          .navigateToDashboard
                      "
                    >
                      Paldesk
                    </h2>
                    <h2
                      fxHide.gt-md
                      [fxHide.gt-sm]="
                        userService.userContext?.partnertype === 5 ||
                        !isAuthorized
                      "
                      data-cy="paldesk-heading-mobile"
                      class="default margin-bottom-0 cursor-pointer align-center fs-20"
                      (click)="navigateToRoot()"
                    >
                      {{ appNameOverride ?? config.appName }}
                    </h2>
                    <div
                      fxLayout.gt-lg="row"
                      [fxHide.lt-md]="
                        !!userService.userContext?.partnertype || !isAuthorized
                      "
                      [fxHide.lt-lg]="
                        userService.userContext?.partnertype !== 5 &&
                        isAuthorized
                      "
                      fxLayoutAlign="center center"
                      class="menu-header"
                    >
                      @if (visibleMenuItems.showApps) {
                        <button
                          [ngClass]="{
                            underline: menuExpandedStates()['apps']
                          }"
                          id="apps-menu-btn"
                          data-cy="apps-menu"
                          #menuButtons
                          mat-button
                          (click)="showOverlay(menus.apps)"
                          [disabled]="loading()"
                          [gaClickListener]="
                            googleAnalyticsConstants.header.clickListener
                              .showApps
                          "
                        >
                          <div fxLayout="row" fxLayoutAlign="start center">
                            {{ 'navigation.apps' | translate | uppercase }}
                            <mat-icon
                              [@rotate180]="
                                menuExpandedStates()['apps']
                                  ? 'rotated'
                                  : 'default'
                              "
                              >keyboard_arrow_down
                            </mat-icon>
                          </div>
                        </button>
                      }
                      @if (visibleMenuItems.showProducts) {
                        <button
                          mat-button
                          id="products-menu-btn"
                          data-cy="products-menu"
                          #menuButtons
                          [ngClass]="{
                            underline: menuExpandedStates()['products']
                          }"
                          (click)="showOverlay(menus.products)"
                          [disabled]="loading()"
                          [gaClickListener]="
                            googleAnalyticsConstants.header.clickListener
                              .showProducts
                          "
                        >
                          <div fxLayout="row" fxLayoutAlign="start center">
                            {{
                              'navigation.product_documents'
                                | translate
                                | uppercase
                            }}
                            <mat-icon
                              [@rotate180]="
                                menuExpandedStates()['products']
                                  ? 'rotated'
                                  : 'default'
                              "
                              >keyboard_arrow_down
                            </mat-icon>
                          </div>
                        </button>
                      }
                      @if (visibleMenuItems.showDocuments) {
                        <button
                          mat-button
                          id="documents-menu-btn"
                          data-cy="documents-menu"
                          #menuButtons
                          [ngClass]="{
                            underline: menuExpandedStates()['documents']
                          }"
                          (click)="showOverlay(menus.documents)"
                          [disabled]="loading()"
                          [gaClickListener]="
                            googleAnalyticsConstants.header.clickListener
                              .showServiceDocuments
                          "
                        >
                          <div fxLayout="row" fxLayoutAlign="start center">
                            {{
                              'navigation.service_documents'
                                | translate
                                | uppercase
                            }}
                            <mat-icon
                              [@rotate180]="
                                menuExpandedStates()['documents']
                                  ? 'rotated'
                                  : 'default'
                              "
                              >keyboard_arrow_down
                            </mat-icon>
                          </div>
                        </button>
                      }
                      @if (visibleMenuItems.showHelp) {
                        <button
                          mat-button
                          id="help-menu-btn"
                          #menuButtons
                          data-cy="help-menu"
                          [ngClass]="{
                            underline: menuExpandedStates()['help']
                          }"
                          (click)="showOverlay(menus.help)"
                          [disabled]="loading()"
                          [gaClickListener]="
                            googleAnalyticsConstants.header.clickListener
                              .showHelp
                          "
                        >
                          <div fxLayout="row" fxLayoutAlign="start center">
                            {{
                              'navigation.help_feedback' | translate | uppercase
                            }}
                            <mat-icon
                              [@rotate180]="
                                menuExpandedStates()['help']
                                  ? 'rotated'
                                  : 'default'
                              "
                              >keyboard_arrow_down
                            </mat-icon>
                          </div>
                        </button>
                      }
                    </div>
                  </div>
                  <div
                    class="right-header"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    [fxLayoutGap]="1 | dsSpacing"
                  >
                    @if (visibleMenuItems.showSearch) {
                      @if (media.isActive('gt-md')) {
                        <ds-new-paldesk-search-menu fxFlex />
                      } @else {
                        <div
                          fxLayout="column"
                          class="cursor-pointer menu-hover"
                          fxLayoutAlign="center center"
                          (click)="switchIsMobileSearchActive()"
                        >
                          <mat-icon
                            class="icon-size"
                            data-cy="search-input-icon"
                            >search</mat-icon
                          >
                          <div>{{ 'forms.btn_search' | translate }}</div>
                        </div>
                      }
                    }
                    @if (visibleMenuItems.showLanguageSelector) {
                      <div
                        fxLayout="column"
                        class="cursor-pointer menu-hover"
                        fxLayoutAlign="center center"
                        data-cy="language-selector"
                        [matMenuTriggerFor]="languageMenu"
                      >
                        <div fxLayout="row" fxLayoutAlign="center center">
                          <mat-icon class="icon-size">language</mat-icon>
                          <div
                            class="selected-language"
                            fxHide.xs
                            fxHide.sm
                            data-cy="language"
                          >
                            {{ currentLanguage.title }}
                          </div>
                        </div>
                        <div fxHide.md fxHide.lg fxHide.xl>
                          {{ translateService.currentLang | uppercase }}
                        </div>
                      </div>
                      <mat-menu #languageMenu="matMenu" data-cy="language-menu">
                        @for (language of languages; track language.code) {
                          <button
                            mat-menu-item
                            (click)="setLanguage(language.code)"
                          >
                            <span>{{ language.title }}</span>
                          </button>
                        }
                      </mat-menu>
                    }
                    <ng-container *ngTemplateOutlet="userMenu" />
                    <ng-template #userMenu>
                      <div
                        fxLayout="column"
                        class="cursor-pointer"
                        id="profile-menu-btn"
                        data-cy="profile"
                        fxLayoutAlign="center center"
                        #menuButtons
                        (click)="showOverlay(menus.profile)"
                        [gaClickListener]="
                          googleAnalyticsConstants.header.clickListener.userMenu
                        "
                      >
                        @if (isAuthorized) {
                          <div
                            fxLayout="column"
                            fxLayoutAlign="center center"
                            class="menu-hover"
                          >
                            <div class="icon-user-menu">
                              {{
                                userService?.userContext?.firstname
                                  | titlecase
                                  | slice: 0 : 1
                              }}{{
                                userService?.userContext?.lastname
                                  | titlecase
                                  | slice: 0 : 1
                              }}
                            </div>
                            <div
                              fxHide.lg
                              fxHide.xl
                              [fxHide.gt-md]="
                                userService.userContext.partnertype !== 5
                              "
                              [fxHide.gt-sm]="
                                userService.userContext?.partnertype === 5
                              "
                            >
                              {{ 'general.profile' | translate }}
                            </div>
                          </div>
                        } @else {
                          <div class="menu-hover">
                            <div fxLayout="row" fxLayoutAlign="center center">
                              <mat-icon
                                class="icon-size"
                                data-cy="anonymous-circle"
                              >
                                account_circle
                              </mat-icon>
                            </div>
                            <div fxHide.md fxHide.lg fxHide.xl class="no-wrap">
                              {{ 'general.sign_in' | translate }}
                            </div>
                          </div>
                        }
                      </div>
                    </ng-template>
                  </div>
                </div>
              } @else {
                <div
                  class="header-wrapper full-height full-width"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  [fxLayoutGap.lt-sm]="0.5 | dsSpacing"
                  [fxLayoutGap]="1.5 | dsSpacing"
                >
                  <mat-icon
                    class="menu-hover"
                    (click)="switchIsMobileSearchActive()"
                    >arrow_back
                  </mat-icon>
                  <ds-new-paldesk-search-menu fxFlex />
                </div>
              }
            </div>
            <ds-paldesk-hc-status-banner />
            <div
              fxLayout
              class="full-height"
              *pdLet="isExpanded$ | async as isExpanded"
            >
              @if (menu || isHelpPageActive) {
                <aside
                  [fxHide.lt-lg]="
                    userService.userContext?.partnertype !== 5 && isAuthorized
                  "
                  fxHide.lt-md
                  [class.mini]="!isExpanded"
                  [fxFlex]="(isExpanded ? 15 : 2) | dsSpacing"
                  [fxFlex.xs]="isExpanded ? '80vw' : (2 | dsSpacing)"
                  class="print-hidden"
                >
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="toggleSidebar(!isExpanded)"
                    data-cy="toggle-sidebar-button"
                    class="drawer-button"
                  >
                    <mat-icon
                      [@indicatorRotate]="isExpanded ? 'expanded' : 'collapsed'"
                    >
                      chevron_right
                    </mat-icon>
                  </button>
                  <ds-menu-v2
                    *ngIf="menu || isHelpPageActive"
                    [isExpanded]="isExpanded"
                    [applicationMenu]="isHelpPageActive ? helpMenu : menu"
                    [menuLoadingCount]="
                      isHelpPageActive
                        ? (isHelpMenuLoading$ | async)
                          ? 5
                          : 0
                        : menuLoadingCount
                    "
                    (helpMenuBackNavigation)="getHelpMenu()"
                    data-cy="ds-menu"
                  />
                </aside>
              }
              <div
                class="scroll-container-vertical content-height full-width"
                id="scrollToTopRestoration"
              >
                <div
                  class="page-content full-height"
                  [class.no-sidebar]="!menu && !isHelpPageActive"
                >
                  @if (!disablePageHeadlines) {
                    <ds-page-actions-v2 />
                  }
                  <div
                    [@dsFadeIn]
                    [@dsFadeOut]
                    *ngIf="isExpanded"
                    class="backdrop cdk-overlay-dark-backdrop print-hidden"
                    fxHide
                    fxHide.xs
                    fxShow.lt-md
                    (click)="toggleSidebar(!isExpanded)"
                  ></div>
                  <ng-content />
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </mat-drawer-content>
</mat-drawer-container>
<ng-template #loader>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" />
</ng-template>
