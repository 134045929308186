import { BusinessPartnerResponse } from '@data-access/bpm-generated';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CustomerFilter } from '../model';
import { CustomerActions } from './customer.actions';

export interface CustomerState extends EntityState<BusinessPartnerResponse> {
  filter: CustomerFilter;
  current?: string;
  loading: boolean;
  created: boolean;
  searchPerformed: boolean;
  searchError: boolean;
  updated: boolean;
  searchResult: BusinessPartnerResponse[];
  searchResultsCount?: number;
  selectedIndustries: string[];
  possibleDuplicates?: BusinessPartnerResponse[];
}
const adapter: EntityAdapter<BusinessPartnerResponse> =
  createEntityAdapter<BusinessPartnerResponse>({
    selectId: (customer) =>
      `${customer.customer_number}_${customer.business_partner_number}_${
        customer.supplier_number || ''
      }`,
  });

const initialState: CustomerState = adapter.getInitialState({
  filter: {
    companyName: '',
    country: '',
    postalCode: '',
    city: '',
  },
  searchResult: [],
  current: undefined,
  loading: false,
  created: false,
  searchPerformed: false,
  searchError: false,
  updated: false,
  selectedIndustries: [],
  possibleDuplicates: undefined,
});

const reducer = createReducer(
  initialState,
  on(CustomerActions.LoadCustomerList, (state) =>
    Object.assign({}, state, {
      loading: true,
      searchResults: undefined,
    }),
  ),

  on(CustomerActions.LoadCustomerSuccess, (state, { payload }) => {
    if (payload) {
      return {
        ...adapter.upsertOne(payload, state),
        loading: false,
        searchError: false,
        current: payload.customer_number,
      };
    } else {
      return state;
    }
  }),
  on(CustomerActions.CreateCustomer, (state) => ({
    ...state,
    possibleDuplicates: undefined,
  })),

  on(CustomerActions.PossibleDuplicatesFound, (state, payload) => ({
    ...state,
    possibleDuplicates: payload.possibleDuplicates,
  })),

  on(CustomerActions.UpdateCustomerSuccess, (state, { payload }) => ({
    ...adapter.upsertOne(payload, state),
    loading: false,
    searchError: false,
    current: payload.customer_number,
    updated: true,
  })),

  on(CustomerActions.LoadCustomerListSuccess, (state, { payload }) => {
    const searchResult = [...payload.business_partners];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchResult.sort((a, b) => (a.is_registered_in_bpm ? -1 : 1));
    return {
      ...state,
      searchResult: searchResult,
      searchResultsCount: payload.count,
      loading: false,
      searchPerformed: true,
      searchError: false,
    };
  }),

  on(CustomerActions.CustomerSearchError, (state) =>
    Object.assign({}, state, {
      loading: false,
      searchPerformed: true,
      searchError: true,
    }),
  ),

  on(CustomerActions.ChangeFilter, (state, { payload }) => ({
    ...state,
    filter: payload,
  })),

  on(CustomerActions.ResetCustomerEdit, (state) => ({
    ...state,
    updated: false,
  })),

  on(CustomerActions.ResetSearchResult, (state) => ({
    ...state,
    searchResult: initialState.searchResult,
    searchResultsCount: undefined,
    possibleDuplicates: undefined,
    searchPerformed: false,
  })),

  on(CustomerActions.UpdateIndustries, (state, { payload }) => ({
    ...state,
    selectedIndustries: payload,
  })),

  on(CustomerActions.ResetPossibleDuplicates, (state) => ({
    ...state,
    possibleDuplicates: undefined,
  })),
);
export const getSelectedCustomerNumer = (state: CustomerState) => state.current;

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectCustomerIds = selectIds;

export const selectCustomerEntities = selectEntities;

export const selectAllCustomers = selectAll;

export function customerReducer(
  state: CustomerState,
  action: Action,
): CustomerState {
  return reducer(state, action);
}
