<mat-list-item (click)="toggleClass(class.document_class_code)" *ngIf="class">
  <div fxLayout fxLayoutAlign="start center" class="full-width">
    <span fxFlex="1 1 auto">{{ class.name || class.document_class_code }}</span>
    <span fxFlex="0 0 auto">{{ class.documents_result_number }}</span>
    <mat-icon
      fxFlex="0 0 auto"
      [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'"
      >keyboard_arrow_down</mat-icon
    >
  </div>
</mat-list-item>
<div
  [style.padding-left]="0.5 | dsSpacing"
  [@bodyExpansion]="expanded ? 'expanded' : 'collapsed'"
  *ngIf="class"
>
  <a
    *ngFor="let document of class.top_documents; let itemIndex = index"
    mat-list-item
    [href]="document.url | documentUrl: userLang"
    target="_blank"
    (click)="itemClick(document, itemIndex)"
  >
    {{ document.title }}
  </a>
  <a
    mat-list-item
    [href]="
      paldeskBasePath +
      '/document/' +
      searchTerm +
      '?document_class=' +
      class.document_class_code +
      '&search_id=' +
      this.searchId
    "
    (click)="
      documentClick.emit({
        index: undefined,
        name: class.document_class_code,
        description: '',
        url:
          '/document/' +
          searchTerm +
          '?document_class=' +
          class.document_class_code
      })
    "
    target="_blank"
  >
    {{ 'header.search.view_all' | translate }}
  </a>
</div>
