<h1 mat-dialog-title>
  {{ 'start_page.bom' | translate }} ({{ materialNumber }})
</h1>
<div mat-dialog-content>
  <ds-table
    fxFlex="1 1 auto"
    *ngIf="(bomContainerLoadingError$ | async) === false; else errorView"
  >
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      *ngIf="
        dataSource?.data?.length && (bomContainerLoading$ | async) === false;
        else !dataSource?.data?.length &&
        (bomContainerLoading$ | async) === false
          ? noResult
          : loading
      "
    >
      <!-- Material number column -->
      <ng-container matColumnDef="material_number">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'start_page.result_table.material_number' | translate }}
        </th>

        <td mat-cell *matCellDef="let element">
          {{ element.material_number }}
        </td>
      </ng-container>

      <!-- description column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'start_page.result_table.short_text' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>

      <!-- amount column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>amount</th>
        <td mat-cell *matCellDef="let element">
          {{ element.amount }}
        </td>
      </ng-container>

      <!-- price column -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'start_page.result_table.price' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.price | currency: element.currency }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ds-table>

  <ng-template #noResult>
    <ds-placeholder
      type="no_data"
      [customHeadline]="'No results found'"
    ></ds-placeholder>
  </ng-template>

  <ng-template #loading>
    <mat-card>
      <mat-card-content>
        <ds-table-loading
          [numberOfHeaders]="9"
          [numberOfRows]="10"
        ></ds-table-loading>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #errorView>
    <ds-placeholder type="error"></ds-placeholder>
  </ng-template>
</div>
<div mat-dialog-actions align="end" class="dialog-actions">
  <button mat-button mat-dialog-close (click)="closeDialog()">
    {{ 'general.close' | translate }}
  </button>
</div>
