import { Action, createReducer, on } from '@ngrx/store';
import { BusinessPartnerSalesOrg } from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { SalesOrgActions } from './salesorg.actions';

export interface SalesOrgState {
  salesOrgs: BusinessPartnerSalesOrg[] | null;
  salesOrgsLoading: boolean;
  salesOrgsLoadingError: boolean;
}

const initialState: SalesOrgState = {
  salesOrgs: [],
  salesOrgsLoading: false,
  salesOrgsLoadingError: false,
};

export const reducer = createReducer(
  initialState,

  on(SalesOrgActions.LoadSalesOrgs, (state) => ({
    ...state,
    salesOrgsLoading: true,
    salesOrgsLoadingError: false,
  })),

  on(SalesOrgActions.LoadSalesOrgsSuccess, (state, { payload }) => ({
    ...state,
    salesOrgs: payload.salesOrgs?.business_partner_sales_orgs,
    salesOrgsLoading: false,
    salesOrgsLoadingError: false,
  })),

  on(SalesOrgActions.LoadSalesOrgsError, (state) => ({
    ...state,
    shippingConditions: null,
    shippingConditionsLoading: false,
    shippingConditionsLoadingError: true,
  })),
);

export function salesOrgReducer(
  state: SalesOrgState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
