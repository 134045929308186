import { CommonModule, NgIf } from '@angular/common';
import { Component, Signal, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FlexModule } from '@angular/flex-layout';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { Application, ApplicationCategory } from '@features/applications';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { AppShortNamePipeModule } from '@utils/app-short-name-pipe';
import { AppWrapperV2Service } from '../../app-wrapper-v2.service';
import { OrderCategoriesPipe } from '../../apps-menu/order-categories.pipe';
import { RemovePlaceholderDescriptions } from '../../pipes/remove-placeholder-descriptions.pipe';
import { DsDefaultImageDirective } from '../../shared/default-image.directive';
import { gAAppWrapperV2Constants } from '../../shared/model';
import { OpenAppInNewTabComponent } from '../../shared/open-app-in-new-tab/open-app-in-new-tab.component';
import { AppWrapperV2Store } from '../../signal-store/app-wrapper-v2-signal-store';

@Component({
  selector: 'ds-new-paldesk-apps-sidebar',
  templateUrl: './apps-sidebar.component.html',
  styleUrls: ['./apps-sidebar.component.scss'],
  standalone: true,
  imports: [
    DsSpacingModule,
    FlexModule,
    MatIconModule,
    NgIf,
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    AppShortNamePipeModule,
    MatProgressSpinnerModule,
    OrderCategoriesPipe,
    RemovePlaceholderDescriptions,
    GoogleAnalytics4DirectiveModule,
    DsDefaultImageDirective,
    OpenAppInNewTabComponent,
  ],
})
export class AppsSidebarComponent {
  filter = new FormControl<string>('');
  appCategoriesFiltered: Signal<ApplicationCategory[] | null>;
  toggleOpenInNewTabSignal: Signal<boolean | null>;

  public googleAnalyticsConstants = gAAppWrapperV2Constants;

  private filterSignal: Signal<string | null | undefined>;

  constructor(
    private _store: AppWrapperV2Store,
    public appWrapperService: AppWrapperV2Service,
  ) {
    this.filterSignal = toSignal(this.filter.valueChanges);
    this.toggleOpenInNewTabSignal = this._store.appsNewTabUserSetting;

    this.appCategoriesFiltered = computed(() => {
      const filterVal = this.filterSignal();
      if (filterVal && filterVal.length) {
        return this._store.filterApps(filterVal);
      }

      return this._store.applications()?.categories || [];
    });
  }

  toggleFavorite(app: Application): void {
    app.isUpdatingFavorite = true;
    if (app.favorite) {
      this._store.removeFavorite(app.id);
    } else {
      this._store.addFavorite(app);
    }
  }
}
