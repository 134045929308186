import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { dsConfig } from '@design-system/cdk/config';
import { DsPageActionDirective } from './page-action.directive';

@Component({
  selector: 'ds-page-actions',
  templateUrl: './page-actions.component.html',
  styleUrls: ['./page-actions.component.scss'],
})
export class DsPageActionsComponent implements AfterViewInit {
  @Input() useMenuMode = false;
  @ContentChildren(DsPageActionDirective) set _directives(
    val: QueryList<DsPageActionDirective>,
  ) {
    if (val) {
      this.actions = val.toArray();
      this.setItemsViewMode();
    }
  }

  @ViewChild('container') container: ElementRef;
  @ViewChild('extra') set _extra(val: ElementRef) {
    if (val.nativeElement.children.length) {
      this.extra = val;
      this.setItemsViewMode();
    }
  }

  extra: ElementRef;
  actions: DsPageActionDirective[];
  listItems: TemplateRef<any>[];
  menuItems: TemplateRef<any>[];
  totalButtonsWidth: number;

  @HostListener('window:resize', ['$event']) onResize() {
    this.setItemsViewMode();
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.setItemsViewMode();
  }

  setItemsViewMode() {
    setTimeout(() => {
      if (this.container && this.actions) {
        this.menuItems = [];
        this.listItems = [];
        let totalContentsWidth =
          (this.extra?.nativeElement
            ? this.extra.nativeElement.offsetWidth + dsConfig.spacing
            : 0) + 42; // 40 = matMenu button width;

        this.actions.forEach((directive) => {
          if (directive) {
            const totalWidthWithItem =
              totalContentsWidth +
              (directive.templateRef?.elementRef?.nativeElement
                ?.nextElementSibling?.offsetWidth ||
                directive.templateRef?.elementRef?.nativeElement
                  ?.previousElementSibling?.offsetWidth ||
                0) +
              dsConfig.spacing;
            if (
              totalWidthWithItem > this.container.nativeElement.offsetWidth ||
              (this.useMenuMode && this.listItems.length > 0)
            ) {
              this.menuItems.push(directive.templateRef);
            } else {
              this.listItems.push(directive.templateRef);
              // only adapt if we keep this button in list
              totalContentsWidth = totalWidthWithItem;
            }
          }
        });

        this.cd.detectChanges();
      }
    }, 0);
  }
}
