import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CountryResponse } from '@data-access/bpm-generated';
import { Store } from '@ngrx/store';
import { nameofFactory } from '@utils/name-of';
import { CustomerActions, CustomerFilter } from '../shared';

const nameof = nameofFactory<CustomerFilter>();

@Component({
  selector: 'paldesk-customer-filter',
  templateUrl: './customer-filter.component.html',
  styleUrls: ['./customer-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerFilterComponent implements OnInit {
  @Input()
  set filterValues(value: CustomerFilter) {
    this.form.patchValue(value);
  }
  @Input() countries?: CountryResponse[];
  @Output()
  newSearch = new EventEmitter<boolean>();
  @Output()
  search = new EventEmitter<CustomerFilter>();

  readonly nameof = nameof;
  readonly form = new UntypedFormGroup({
    [nameof('companyName')]: new UntypedFormControl(
      undefined,
      Validators.required,
    ),
    [nameof('country')]: new UntypedFormControl(undefined, Validators.required),
    [nameof('city')]: new UntypedFormControl(undefined),
    [nameof('postalCode')]: new UntypedFormControl(undefined),
  });

  constructor(private store: Store<any>) {}
  ngOnInit() {
    this.store.dispatch(CustomerActions.ResetSearchResult());
  }

  /**
   * get values from form and emit them in customer filter object
   */
  startSearch() {
    const filter = <CustomerFilter>{};
    filter.companyName = this.form.controls[nameof('companyName')].value;
    filter.country = this.form.controls[nameof('country')].value;
    filter.postalCode = this.form.controls['postalCode'].value;
    filter.city = this.form.controls['city'].value;
    this.search.emit(filter);
    this.newSearch.emit(true);
  }
}
