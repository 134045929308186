import { createAction, props } from '@ngrx/store';
import {
  BusinessPartnerSalesOrgContainer,
  ModelError,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';

export namespace SalesOrgActions {
  export const LoadSalesOrgs = createAction('[SparepartsShop] LOAD_SALES_ORGS');

  export const LoadSalesOrgsSuccess = createAction(
    '[SparepartsShop] LOAD_SALES_ORGS_SUCCESS',
    props<{
      payload: { salesOrgs: BusinessPartnerSalesOrgContainer };
    }>(),
  );

  export const LoadSalesOrgsError = createAction(
    '[SparepartsShop] LOAD_SALES_ORGS_ERROR',
    props<{
      payload: { error: ModelError };
    }>(),
  );
}
