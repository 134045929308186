import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import {
  ModelError,
  Order,
  PaginatedOrderHistory,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { OrderHistoryFilters } from '../../shared/models';

export namespace OrderHistoryActions {
  export const LoadOrderHistoryById = createAction(
    '[Order History] LOAD_ORDER_HISTORY_BY_ID',
    props<{ payload: number }>(),
  );

  export const LoadOrderHistoryByIdSuccess = createAction(
    '[Order History] LOAD_ORDER_HISTORY_BY_ID_SUCCESS',
    props<{ payload: Order }>(),
  );

  export const LoadOrderHistoryByIdError = createAction(
    '[Order History] LOAD_ORDER_HISTORY_BY_ID_ERROR',
    props<{ payload: ModelError }>(),
  );

  export const SearchOrderHistory = createAction(
    '[Order History] SEARCH_ORDER_HISTORY',
  );

  export const SearchOrderHistorySuccess = createAction(
    '[Order History] SEARCH_ORDER_HISTORY_SUCCESS',
    props<{ payload: PaginatedOrderHistory }>(),
  );

  export const SearchOrderHistoryError = createAction(
    '[Order History] SEARCH_ORDER_HISTORY_ERROR',
    props<{ payload: ModelError }>(),
  );

  export const FilterChange = createAction(
    '[Order History] FILTER_CHANGE',
    props<{ payload: OrderHistoryFilters }>(),
  );
  export const SortChange = createAction(
    '[Order History] SORT_CHANGE',
    props<{ payload: Sort }>(),
  );
  export const PageChange = createAction(
    '[Order History] PAGE_CHANGE',
    props<{ payload: PageEvent }>(),
  );

  export const ExportOrderHistory = createAction(
    '[Order History] EXPORT_ORDER_HISTORY',
  );

  export const ExportOrderHistorySuccess = createAction(
    '[Order History] EXPORT_ORDER_HISTORY_SUCCESS',
    props<{ payload: { data: Blob; name: string } }>(),
  );
  export const ExportOrderHistoryError = createAction(
    '[Order History] EXPORT_ORDER_HISTORY_ERROR',
    props<{ payload: ModelError }>(),
  );

  export const ExportOrderHistoryDetail = createAction(
    '[Order History] EXPORT_ORDER_HISTORY_DETAIL',
    props<{ payload: number }>(),
  );

  export const ExportOrderHistoryDetailSuccess = createAction(
    '[Order History] EXPORT_ORDER_HISTORY_DETAIL_SUCCESS',
    props<{ payload: { data: Blob; name: string } }>(),
  );
  export const ExportOrderHistoryDetailError = createAction(
    '[Order History] EXPORT_ORDER_HISTORY_DETAIL_ERROR',
    props<{ payload: ModelError }>(),
  );

  export const ClearFilter = createAction('[Order History] CLEAR_FILTER');
}
