import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserContext, UserService } from '@features/auth';
import { SurveyDialogComponent } from './survey-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class SurveyDialogService {
  static experimentalDashboardSurveyId = 'SV_2bKIlZGdJToFmwC';
  static paldeskCSISurveyId = 'SV_2ohW9opQSHc8yOi';

  user: UserContext | undefined;
  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private destroyRef: DestroyRef,
  ) {
    this.userService.currentUser
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.user = res;
      });
  }

  openQualtricsSurvey(
    surveyId: string,
  ): MatDialogRef<SurveyDialogComponent> | undefined {
    if (this.user) {
      const surveyUrl: string = this.constructSurveyUrl(this.user, surveyId);
      const surveyDialog: MatDialogRef<SurveyDialogComponent> =
        this.dialog.open(SurveyDialogComponent);
      surveyDialog.componentInstance.data = { surveyUrl };
      surveyDialog.componentInstance.closed
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            surveyDialog.close();
          },
        });
      return surveyDialog;
    }
    return undefined;
  }

  constructSurveyUrl(user: UserContext, surveyUrl: string): string {
    return (
      `https://survey.palfinger.com/jfe/form/${surveyUrl}` +
      `?paldesk_username=${user.username}` +
      `&paldesk_email=${user.email}` +
      `&paldesk_firstname=${encodeURIComponent(user.firstname)}` +
      `&paldesk_lastname=${encodeURIComponent(user.lastname)}` +
      `&paldesk_language=${user.lang}` +
      `&paldesk_country=${encodeURIComponent(user.country)}` +
      `&paldesk_company_name=${encodeURIComponent(user.company_name)}` +
      `&paldesk_company_sapid=${user.company_sapid}` +
      `&paldesk_partnertype=${user.partnertype}` +
      `&paldesk_page=${window.location.hostname}`
    );
  }
}
