import { BusinessPartnerResponse } from '@data-access/bpm-generated';
import { Action, createReducer, on } from '@ngrx/store';
import {
  ModelError,
  OrderRequest,
  OrderResponse,
  Shipping,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { CheckOutActions } from './checkout.actions';

export interface CheckOutState {
  shippingConditions: Shipping | null;
  shippingConditionsLoading: boolean;
  shippingConditionsLoadingError: boolean;
  shippingBusinessPartner?: BusinessPartnerResponse;
  submitOrderResponse: OrderResponse | null;
  submitOrderLoading: boolean;
  submitOrderError?: ModelError;
  orderRequest: OrderRequest | null;
  validOrderRequest: boolean;
}

const initialState: CheckOutState = {
  shippingConditions: null,
  shippingConditionsLoading: false,
  shippingConditionsLoadingError: false,
  shippingBusinessPartner: undefined,
  submitOrderResponse: null,
  submitOrderLoading: false,
  submitOrderError: undefined,
  orderRequest: null,
  validOrderRequest: false,
};

export const reducer = createReducer(
  initialState,

  /**
   * Shipping conditions
   */
  on(CheckOutActions.LoadShippingConditions, (state) => ({
    ...state,
    shippingConditionsLoading: true,
    shippingConditionsLoadingError: false,
  })),

  on(CheckOutActions.LoadShippingConditionsSuccess, (state, { payload }) => ({
    ...state,
    shippingConditions: payload.conditions,
    shippingConditionsLoading: false,
    shippingConditionsLoadingError: false,
  })),

  on(CheckOutActions.LoadShippingConditionsError, (state) => ({
    ...state,
    shippingConditionsLoading: false,
    shippingConditionsLoadingError: true,
  })),

  /**
   * Shipping customer id
   */

  on(CheckOutActions.SetShippingBusinessPartner, (state, { payload }) => ({
    ...state,
    shippingBusinessPartner: payload.bp,
  })),

  /**
   * Valid checkout options
   */

  on(CheckOutActions.SetOrderRequest, (state, { payload }) => ({
    ...state,
    orderRequest: payload.request,
    validOrderRequest: payload.orderFormValid,
  })),

  /**
   * submit order
   */
  on(CheckOutActions.SubmitOrder, (state) => ({
    ...state,
    submitOrderResponse: null,
    submitOrderLoading: true,
    submitOrderError: undefined,
  })),

  on(CheckOutActions.SubmitOrderSuccess, (state, { payload }) => ({
    ...state,
    submitOrderResponse: payload,
    submitOrderLoading: false,
    submitOrderError: undefined,
  })),

  on(CheckOutActions.SubmitOrderError, (state, { payload }) => ({
    ...state,
    submitOrderResponse: null,
    submitOrderLoading: false,
    submitOrderError: payload.error,
  })),
);

export function checkOutReducer(
  state: CheckOutState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
