import { Action, createReducer, on } from '@ngrx/store';
import {
  Cart,
  ModelError,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { CartActions } from './cart.actions';

export interface CartState {
  cart: Cart | null;
  cartLoading: boolean;
  cartLoadingError: boolean;
  cartLoadingErrorMsg: ModelError | null;
  totalCartItems: number | null;
  addedId: number | null;
}

const initialState: CartState = {
  cart: null,
  cartLoading: false,
  cartLoadingError: false,
  cartLoadingErrorMsg: null,
  totalCartItems: null,
  addedId: null,
};

export const reducer = createReducer(
  initialState,

  /**
   * Cart
   */

  on(CartActions.LoadShoppingCart, (state) => ({
    ...state,
    cartLoading: true,
    cartLoadingError: false,
    cartLoadingErrorMsg: null,
  })),

  on(
    CartActions.LoadShoppingCartSuccess,
    CartActions.ChangeSalesOrgForCurrentCartSuccess,
    CartActions.ChangeSparepartShopSuccess,
    (state, { payload }) => {
      let itemAmount = 0;
      payload.cart.cart_items.forEach((item) => {
        itemAmount += item.quantity;
      });
      return {
        ...state,
        totalCartItems: itemAmount,
        cart: payload.cart,
        cartLoading: false,
      };
    },
  ),

  on(CartActions.LoadShoppingCartError, (state, { payload }) => ({
    ...state,
    cartResults: null,
    cartLoading: false,
    cartLoadingError: true,
    cartLoadingErrorMsg: payload.error,
  })),

  /**
   * Add to cart & Quick update to cart
   */

  on(
    CartActions.QuickAddToShoppingCartSuccess,
    CartActions.AddToShoppingCartSuccess,
    (state, { payload }) => ({
      ...state,
      totalCartItems: payload.itemAmount,
    }),
  ),

  /**
   * Update/delete cart amount/item
   */

  on(
    CartActions.UpdateCartItemQuatitySuccess,
    CartActions.DeleteFromShoppingCartSuccess,
    (state, { payload }) => {
      let itemAmount = 0;
      payload.cart.cart_items.forEach((item) => {
        itemAmount += item.quantity;
      });
      return {
        ...state,
        totalCartItems: itemAmount,
        cart: payload.cart,
      };
    },
  ),

  /**
   * Delete all from cart
   */

  on(
    CartActions.DeleteAllItemsFromShoppingCartSuccess,
    (state, { payload }) => ({
      ...state,
      cart: payload.cart,
    }),
  ),
);

export function cartReducer(state: CartState | undefined, action: Action) {
  return reducer(state, action);
}
