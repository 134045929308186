import { Injectable } from '@angular/core';
import { UserService } from '@features/auth';
import {
  SearchHistory,
  SearchHistorySchema,
  SearchResultLink,
} from '@features/search';
import { StorageMap } from '@ngx-pwa/local-storage';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchRecentService {
  storageKey: string;

  constructor(
    private _storageMap: StorageMap,
    private _userService: UserService,
    private _messageService: MessengerService,
  ) {
    this._userService.currentUser?.subscribe(
      (userContext) => (this.storageKey = `search-history-${userContext?.sub}`),
    );
  }

  addResult(key: string, result: SearchResultLink): Observable<SearchHistory> {
    return this.getHistory().pipe(
      switchMap((h) => {
        const results = h.results[key] || [];
        const stringifiedResult = JSON.stringify(result);
        const indexToRemove = results.findIndex(
          (r) => JSON.stringify(r) === stringifiedResult,
        );
        if (indexToRemove !== -1) {
          results.splice(indexToRemove, 1);
        }
        results.unshift(result);
        h.results[key] = results.slice(0, 20);
        return this._setStorage(h, 'search-recent-add-result').pipe(
          map(() => h),
        );
      }),
    );
  }

  removeResult(
    key: string,
    result: SearchResultLink,
  ): Observable<SearchHistory> {
    return this.getHistory().pipe(
      switchMap((h) => {
        const results = h.results[key];
        if (!results || !results.length) return EMPTY;
        const resultIndex = results
          .map((r) => JSON.stringify(r))
          .indexOf(JSON.stringify(result));
        if (resultIndex < 0) return EMPTY;
        results.splice(resultIndex, 1);
        h.results[key] = results;
        return this._setStorage(h, 'search-recent-remove-result').pipe(
          map(() => h),
        );
      }),
    );
  }

  clearHistory(key: string): Observable<SearchHistory> {
    return this.getHistory().pipe(
      switchMap((h) => {
        const results = h.results[key];
        if (!results) return EMPTY;
        delete h.results[key];
        return this._setStorage(h, 'search-recent-clear-history').pipe(
          map(() => h),
        );
      }),
    );
  }

  getHistory(): Observable<SearchHistory> {
    return this._storageMap
      .get<SearchHistory>(this.storageKey, SearchHistorySchema)
      .pipe(
        catchError((err) =>
          this._tryLogJSONSchemaInvalid(err, 'search-recent-get-history'),
        ),
        map((history) =>
          !(!history || !history.results)
            ? history
            : ({ results: {} } as SearchHistory),
        ), // if truthy return value of history, else empty instead of undefined
      );
  }

  private _setStorage(
    value: SearchHistory,
    source: string,
  ): Observable<undefined> {
    return this._storageMap
      .set(this.storageKey, value, SearchHistorySchema)
      .pipe(catchError((err) => this._tryLogJSONSchemaInvalid(err, source)));
  }

  private _tryLogJSONSchemaInvalid(err: any, source: string): Observable<any> {
    if (
      typeof err === 'string' &&
      err.includes('Data stored is not valid against the provided JSON schema')
    ) {
      this._messageService.sendDetailMessage({
        severity: MessageSeverityType.error,
        message: 'Data stored is not valid against the provided JSON schema',
        detailObject: err,
        source: source,
        targets: [MessageTargetType.log],
      });
      return EMPTY;
    }
    return throwError(() => err);
  }
}
