import { NgModule } from '@angular/core';
import { MenuComponent } from './menu.component';
import { SharedWrapperModule } from '@design-system/feature/app-wrapper';

@NgModule({
  declarations: [MenuComponent],
  imports: [SharedWrapperModule],
  exports: [MenuComponent],
})
export class MenuModule {}
