import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgIf, UpperCasePipe } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import {
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
  Menu,
  SharedWrapperModule,
} from '@design-system/feature/app-wrapper';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { DocumentType } from '../model/document-type.model';
import { MenuConfigModel } from '../model/menu-config.model';
import { DocumentsSidebarComponent } from '../shared/documents-sidebar/documents-sidebar.component';
import { gAAppWrapperV2Constants } from '../shared/model';
import { AppsSidebarComponent } from './apps-sidebar/apps-sidebar.component';
import { MenuModule } from './menu/menu.module';
import { SidebarHelpAndFeedbackComponent } from './sidebar-help-and-feedback/sidebar-help-and-feedback.component';
import { dsAnimations } from '@design-system/cdk/animations';

@Component({
  selector: 'ds-sidebar-mobile',
  templateUrl: './sidebar-mobile.component.html',
  styleUrls: ['./sidebar-mobile.component.scss'],
  standalone: true,
  imports: [
    DsSpacingModule,
    FlexModule,
    MatIconModule,
    NgIf,
    TranslateModule,
    UpperCasePipe,
    MatExpansionModule,
    MatTreeModule,
    DocumentsSidebarComponent,
    BrowserAnimationsModule,
    AppsSidebarComponent,
    SidebarHelpAndFeedbackComponent,
    MatDividerModule,
    SharedWrapperModule,
    MenuModule,
    MatButtonModule,
    GoogleAnalytics4DirectiveModule,
  ],
  animations: [
    trigger('openClose', [
      state(
        'true', //open
        style({
          height: '*',
          overflow: 'hidden',
        }),
      ),
      state(
        'false', //close
        style({
          height: '0',
          overflow: 'hidden',
        }),
      ),
      transition(
        'true <=> false',
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
    dsAnimations.rotate90,
  ],
})
export class SidebarMobileComponent {
  @Input()
  visibleMenuItems: MenuConfigModel;
  @Input()
  drawer: MatDrawer;
  @Input()
  appIcon?: string;
  @Input()
  menu?: Menu;
  @Input()
  appNameOverride?: string;

  public googleAnalyticsConstants = gAAppWrapperV2Constants;

  documentType = DocumentType;
  productsExpanded = false;
  documentsExpanded = false;
  appsExpanded = false;
  helpAndFeedbackExpanded = false;
  constructor(
    @Inject(DS_APP_WRAPPER_CONFIG) public config: DsAppWrapperConfig,
  ) {}

  navigateToDashboard() {
    window.location.href = this.config.paldeskBasePath;
  }

  close() {
    this.productsExpanded = false;
    this.drawer.toggle();
  }
}
