import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsPageModule } from '@design-system/feature/page';
import { DsPrintModule } from '@design-system/feature/print';
import { DsSnackbarModule } from '@design-system/feature/snackbar';
import { TranslateModule } from '@ngx-translate/core';
import { CheckOutPageModule } from './checkout-page/checkout-page.module';
import { CreateOrderRoutingModule } from './create-order-routing.module';
import { CreateOrderComponent } from './create-order.component';
import { OrderDialogComponent } from './order-dialog/order-dialog.component';
import { SearchPageModule } from './search-page/search-page.module';
@NgModule({
  declarations: [CreateOrderComponent, OrderDialogComponent],
  imports: [
    CommonModule,
    SearchPageModule,
    CheckOutPageModule,
    CreateOrderRoutingModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    DsPlaceholderModule,
    MatMenuModule,
    DsSpacingModule,
    CommonModule,
    DsPageModule,
    FlexLayoutModule,
    TranslateModule,
    PdLetDirectiveModule,
    DsSnackbarModule,
    RouterModule,
    DsPrintModule,
    DsLoadingModule,
  ],
  exports: [CreateOrderComponent],
})
export class CreateOrderModule {}
