import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@features/auth';
import { CheckOutPageComponent } from './checkout-page/checkout-page.component';
import { CreateOrderComponent } from './create-order.component';
import { SearchPageComponent } from './search-page/search-page.component';

const routes: Routes = [
  {
    path: 'create-order',
    component: CreateOrderComponent,
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    children: [
      {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full',
      },
      {
        path: 'search',
        component: SearchPageComponent,
      },
      {
        path: 'checkout',
        component: CheckOutPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreateOrderRoutingModule {}
