import { Component } from '@angular/core';

@Component({
  selector: 'ds-search-result',
  template: '',
})
export class SearchResultComponent {
  viewAll = false;

  toggleViewAll() {
    this.viewAll = !this.viewAll;
  }
}
