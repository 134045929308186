export interface Segment {
  name: string;
  disabled?: boolean;
}

export interface Market {
  id: string;
  name: string;
  disabled?: boolean;
}

export const Industries: { [key: string]: Market[] } = {
  Construction: [
    { id: 'SAM01', name: 'Building Materials Handling' },
    { id: 'SAM02', name: 'General Construction' },
    { id: 'SAM03', name: 'Mason / Natural Stone' },
    { id: 'SAM04', name: 'Road Construction / Maintenance' },
    { id: 'SAM05', name: 'Roofing' },
    { id: 'SAM06', name: 'Scaffolders' },
    { id: 'SAM07', name: 'Window / Glas' },
    { id: 'SAM63', name: 'Wholesale - Sanitary Products' },
  ],
  'Forestry & Agriculture': [
    { id: 'SBM08', name: 'Feed / Fertilizer Handling' },
    { id: 'SBM09', name: 'Forestry and Agricultural Machinery' },
    { id: 'SBM10', name: 'Landscaping' },
    { id: 'SBM11', name: 'Timber Processing' },
    { id: 'SBM12', name: 'Round Timber Transportation' },
  ],
  Industry: [
    { id: 'SCM14', name: 'Hydraulic Engineering' },
    { id: 'SCM15', name: 'Machinery Building / Mechanical Engineering' },
    { id: 'SCM16', name: 'Metalworking - Iron / Steel / Aluminum' },
    { id: 'SCM17', name: 'Mining' },
    { id: 'SCM18', name: 'Oil & Gas / Fuels' },
    { id: 'SCM19', name: 'Rubber / Tyre' },
    { id: 'SCM20', name: 'Synthetics' },
    { id: 'SCM52', name: 'Finished Sawn Timber Transportation' },
  ],
  Infrastructure: [
    { id: 'SDM21', name: 'Bridge Inspection & Repair (Road)' },
    { id: 'SDM22', name: '(Industrial) Building Cleaning & Maintenance' },
    { id: 'SDM23', name: 'Power Plants' },
    { id: 'SDM24', name: 'Power Utilities' },
    { id: 'SDM25', name: 'Renewable Energy (Wind & Solar)' },
    { id: 'SDM26', name: 'Telecommunication & TV' },
    { id: 'SDM62', name: 'Lighting, Street Furniture' },
  ],
  Railway: [
    { id: 'SEM27', name: 'Bridge Inspection & Repair (Railway)' },
    { id: 'SEM28', name: 'Railroad Construction' },
    { id: 'SEM29', name: 'Railroad Maintenance & Intervention' },
  ],
  'Public Sector': [
    { id: 'SFM30', name: 'Emergency Services' },
    { id: 'SFM31', name: 'Military' },
    { id: 'SFM32', name: 'Municipality' },
    { id: 'SFM53', name: 'Firefighters' },
  ],
  'Transport & Logistics': [
    { id: 'SGM33', name: 'Emergency (Wrecking) Support / Towing' },
    { id: 'SGM34', name: 'Events / Tradeshows' },
    { id: 'SGM35', name: 'Freight Forwarder' },
    { id: 'SGM37', name: 'Heavy Load Transportation' },
    { id: 'SGM38', name: 'Cabins & Containers' },
    { id: 'SGM40', name: 'Spot Hire / Renting Agencies' },
    {
      id: 'SGM41',
      name: 'Retail FMCG (Supermarkets incl Beverage, Home Improvement)',
    },
  ],
  'Waste Management & Recycling': [
    { id: 'SHM42', name: 'Bulk Waste' },
    { id: 'SHM43', name: 'Container Discharge' },
    { id: 'SHM45', name: 'Recycling' },
    { id: 'SHM46', name: 'Scrap Metal Handling' },
  ],
  OEM: [
    { id: 'SIM47', name: 'Bodybuilder' },
    { id: 'SIM48', name: 'Chassis Manufacturer / Distributor' },
    { id: 'SIM64', name: 'OEM - Forest Machinery' },
    { id: 'SIM65', name: 'OEM - Chipper Applications' },
    { id: 'SIM66', name: 'OEM - Tractor Applications' },
  ],
  'Rental & Leasing': [
    { id: 'SJM49', name: 'Rental' },
    { id: 'SJM50', name: 'Leasing' },
  ],
  'Used Equipment Distribution': [{ id: 'SKM51', name: 'Used Units' }],
  Sea: [
    { id: 'SMM70', name: 'Offshore' },
    { id: 'SMM71', name: 'Marine' },
    { id: 'SMM72', name: 'Cruise' },
    { id: 'SMM73', name: 'Navy&Coast' },
    { id: 'SMM74', name: 'Wind' },
  ],
};

export const IndustriesDE: { [key: string]: Market[] } = {
  Bauwirtschaft: [
    { id: 'SAM01', name: 'Baustofftransport' },
    { id: 'SAM02', name: 'Hoch- & Tiefbau' },
    { id: 'SAM03', name: 'Steinmetz' },
    { id: 'SAM04', name: 'Straßenbau' },
    { id: 'SAM05', name: 'Dachdecker & Zimmerer' },
    { id: 'SAM06', name: 'Gerüstbau' },
    { id: 'SAM07', name: 'Fenster & Glas' },
    { id: 'SAM63', name: 'Sanitärgroßhandel' },
  ],
  'Land & Forst-wirtschaft': [
    { id: 'SBM08', name: 'Futtermittel' },
    { id: 'SBM09', name: 'Maschinen für Land- und Forstwirtschaft' },
    { id: 'SBM10', name: 'Garten- & Landschaftsbau' },
    { id: 'SBM11', name: 'Holzverarbeitung' },
    { id: 'SBM12', name: 'Holztransport (Rundholz)' },
  ],
  Industrie: [
    { id: 'SCM14', name: 'Wasserbau' },
    { id: 'SCM15', name: 'Maschinenbau' },
    { id: 'SCM16', name: 'Metallindustrie' },
    { id: 'SCM17', name: 'Minen' },
    { id: 'SCM18', name: 'Öl & Gas / Treibstoffe' },
    { id: 'SCM19', name: 'Gummi & Reifen' },
    { id: 'SCM20', name: 'Kunststoffindustrie' },
    { id: 'SCM52', name: 'Holzlogistik (Schnittholz)' },
  ],
  Infrastruktur: [
    { id: 'SDM21', name: 'Brückeninspektion – Straße' },
    { id: 'SDM22', name: 'Gebäudereinigung' },
    { id: 'SDM23', name: 'Kraftwerke' },
    { id: 'SDM24', name: 'Energieversorgung' },
    { id: 'SDM25', name: 'Erneuerbare Energie' },
    { id: 'SDM26', name: 'Telekommunikation' },
    { id: 'SDM62', name: 'Straßenbeleuchtung & -ausstattung' },
  ],
  Railway: [
    { id: 'SEM27', name: 'Brückeninspektion – Railway' },
    { id: 'SEM28', name: 'Schienenbau' },
    { id: 'SEM29', name: 'Schienen Instandhaltung' },
  ],
  'Staatliche Organisationen': [
    { id: 'SFM30', name: 'Hilfsorganisationen' },
    { id: 'SFM31', name: 'Militär' },
    { id: 'SFM32', name: 'Städte & Gemeinden' },
    { id: 'SFM53', name: 'Feuerwehr' },
  ],
  'Transport & Logistik': [
    { id: 'SGM33', name: 'Abschleppdienst' },
    { id: 'SGM34', name: 'Veranstaltungen' },
    { id: 'SGM35', name: 'Speditionen' },
    { id: 'SGM37', name: 'Schwertransport' },
    { id: 'SGM38', name: 'Mobile Raumzellen' },
    { id: 'SGM40', name: 'Verleiher' },
    {
      id: 'SGM41',
      name: 'Einzelhandel & Getränkelogistik',
    },
  ],
  Entsorgungswirtschaft: [
    { id: 'SHM42', name: 'Sperrmüll' },
    { id: 'SHM43', name: 'Containerentleerung' },
    { id: 'SHM45', name: 'Recycling' },
    { id: 'SHM46', name: 'Schrott' },
  ],
  OEM: [
    { id: 'SIM47', name: 'Karosseriebauer' },
    { id: 'SIM48', name: 'LKW Hersteller / Händler' },
    { id: 'SIM64', name: 'OEM – Forstmaschinen' },
    { id: 'SIM65', name: 'OEM – Hackereinsatz' },
    { id: 'SIM66', name: 'OEM – Traktoreinsatz' },
  ],
  'Vermietung & Leasing': [
    { id: 'SJM49', name: 'Vermietung' },
    { id: 'SJM50', name: 'Leasing' },
  ],
  'Used Equipment Distribution': [{ id: 'SKM51', name: 'Gebrauchtgeräte' }],
  Sea: [
    { id: 'SMM70', name: 'Offshore' },
    { id: 'SMM71', name: 'Marine' },
    { id: 'SMM72', name: 'Cruise' },
    { id: 'SMM73', name: 'Navy&Coast' },
    { id: 'SMM74', name: 'Wind' },
  ],
};
