import { createSelector } from '@ngrx/store';
import { SparepartsShopState } from '..';
import { OrderHistoryState } from './order-history.reducer';

export namespace OrderHistorySelectors {
  const orderHistoryState = (state: SparepartsShopState) => state.orderHistory;
  export const pagination = createSelector(
    orderHistoryState,
    (state) => state.pagination,
  );
  export const pageIndex = createSelector(
    pagination,
    (state) => state.pageIndex,
  );
  export function getPage(state: OrderHistoryState) {
    const pageIds = state.pages[state.pagination.pageIndex];
    return pageIds && pageIds.map((id) => state.entities[id]);
  }
  export const currentPage = createSelector(orderHistoryState, (state) =>
    getPage(state),
  );
  export const currentPageItems = createSelector(
    orderHistoryState,
    pageIndex,
    (state, page) => {
      const pageIds = state.pages[page];
      return (
        pageIds &&
        pageIds
          .map((id) => state.entities[id])
          .filter((value): value is NonNullable<typeof value> => !!value)
      );
    },
  );

  export const filters = createSelector(
    orderHistoryState,
    (state) => state.filters,
  );
  export const getOrderDetail = createSelector(
    orderHistoryState,
    (state) => state.currentOrder,
  );

  export const getErrorLoading = createSelector(
    orderHistoryState,
    (state) => state.loadingError,
  );

  export const getExportLoading = createSelector(
    orderHistoryState,
    (state) => state.exportLoading,
  );
}
