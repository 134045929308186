import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '@features/auth';
import { SearchResult, SearchResultLink } from '@features/search';
import { SearchResultComponent } from '../shared/search-result/search-result.component';

@Component({
  selector: 'ds-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent extends SearchResultComponent {
  @Input() documents: SearchResult.Documents;
  @Input() set viewAllByDefault(value: boolean) {
    this.viewAll = value;
  }
  @Input() paldeskBasePath: string;
  @Output() itemClick = new EventEmitter<SearchResultLink>();
  @Input() searchTerm = '';
  @Input() searchId: string;

  readonly userLang;
  expandedClassCode: string;

  constructor(private userService: UserService) {
    super();
    this.userLang = this.userService.userContext.lang;
  }

  toggleClass(classCode: string) {
    if (this.expandedClassCode === classCode) {
      this.expandedClassCode = '';
    } else {
      this.expandedClassCode = classCode;
    }
  }
}
