import { ActivatedRouteSnapshot, Routes, Route } from '@angular/router';
import { AuthGuard, AuthPermissions } from '@features/auth';
import { CreateOrderComponent } from './create-order/create-order.component';
import { appRoles } from './shared/models';
import { inject } from '@angular/core';

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    data: {
      permissions: {
        roles: appRoles,
      } as AuthPermissions,
    },
    children: [
      {
        path: '',
        redirectTo: 'create-order/search',
        pathMatch: 'full',
      },
      {
        path: 'create-order/search',
        component: CreateOrderComponent,
      },
      {
        path: 'order-history',
        canMatch: [(next: Route) => inject(AuthGuard).canMatch(next)],
        loadChildren: () =>
          import('./order-history/order-history.module').then(
            (m) => m.OrderHistoryModule,
          ),
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];
