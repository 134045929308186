import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RemoveLeadingZerosPipe } from './remove-leading-zeros.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [RemoveLeadingZerosPipe],
  exports: [RemoveLeadingZerosPipe],
})
export class RemoveLeadingZerosPipeModule {}
