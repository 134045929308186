import { PageEvent } from '@angular/material/paginator';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Order } from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { OrderHistoryFilters } from '../../shared/models';
import { OrderHistoryActions } from './order-history.actions';

export interface OrderHistoryState extends EntityState<Order> {
  currentOrder?: Order;
  filters: OrderHistoryFilters;
  pagination: PageEvent;
  loadingError: boolean;
  exportLoading: boolean;
  pages: { [id: string]: number[] };
}
const adapter: EntityAdapter<Order> = createEntityAdapter<Order>({
  selectId: (order) => order.id,
});
export const initialState: OrderHistoryState = adapter.getInitialState({
  currentOrder: undefined,
  loadingError: false,
  exportLoading: false,
  pagination: {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  },
  filters: {
    SearchTerm: '',
  },
  pages: {},
});

const reducer = createReducer(
  initialState,
  on(OrderHistoryActions.FilterChange, (state, { payload }) => ({
    ...state,
    filters: payload,
  })),
  on(OrderHistoryActions.PageChange, (state, { payload }) => ({
    ...state,
    pagination: payload,
  })),

  on(OrderHistoryActions.ClearFilter, (state) => ({
    ...state,
    filters: initialState.filters,
  })),

  on(OrderHistoryActions.LoadOrderHistoryById, (state) => ({
    ...state,
    loadingError: false,
  })),
  on(OrderHistoryActions.SearchOrderHistory, (state) => ({
    ...state,
    loadingError: false,
  })),

  on(OrderHistoryActions.LoadOrderHistoryByIdError, (state) => ({
    ...state,
    loadingError: true,
  })),
  on(OrderHistoryActions.SearchOrderHistoryError, (state) => ({
    ...state,
    loadingError: true,
  })),
  on(OrderHistoryActions.LoadOrderHistoryByIdSuccess, (state, { payload }) => ({
    ...state,
    currentOrder: payload,
  })),
  on(OrderHistoryActions.SearchOrderHistorySuccess, (state, { payload }) => ({
    ...adapter.upsertMany(payload.items, state),
    pagination: {
      ...state.pagination,
      length: payload.total_count,
    },
    pages: {
      ...state.pages,
      [state.pagination.pageIndex]: payload.items.map((order) => order.id),
    },
  })),
  on(OrderHistoryActions.ExportOrderHistory, (state) => ({
    ...state,
    exportLoading: true,
  })),
  on(OrderHistoryActions.ExportOrderHistorySuccess, (state) => ({
    ...state,
    exportLoading: false,
  })),
  on(OrderHistoryActions.ExportOrderHistoryError, (state) => ({
    ...state,
    exportLoading: false,
  })),
  on(OrderHistoryActions.ExportOrderHistoryDetail, (state) => ({
    ...state,
    exportLoading: true,
  })),
  on(OrderHistoryActions.ExportOrderHistoryDetailSuccess, (state) => ({
    ...state,
    exportLoading: false,
  })),
  on(OrderHistoryActions.ExportOrderHistoryDetailError, (state) => ({
    ...state,
    exportLoading: false,
  })),
);

export function orderHistoryReducer(
  state: OrderHistoryState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
