<div fxLayout="column" class="full-height">
  <ds-page-sticky-header fxFlex="0 0 auto">
    <div>
      <a
        mat-button
        back-button
        *ngIf="checkoutView"
        [routerLink]="'./'"
        class="print-hidden"
      >
        <mat-icon>arrow_back</mat-icon>
        {{ 'start_page.go_back_to_search' | translate }}
      </a>
    </div>
    <h1
      [ngClass]="checkoutView ? 'no-margin-top' : ''"
      fxLayout="row wrap"
      class="print-hidden"
      fxLayoutAlign="space-between center"
      [fxLayoutGap]="1 | dsSpacing"
    >
      <div
        fxLayout="row wrap"
        fxLayoutAlign="space-between center"
        [fxLayoutGap]="1 | dsSpacing"
      >
        <ng-container
          *ngIf="currentSparepartType$ | async as currentSparepartType"
        >
          {{ 'start_page.sparepart_shop_' + currentSparepartType | translate }}
        </ng-container>

        <ng-container
          *ngIf="currentSelectedSalesOrg$ | async as currentSelectedSalesOrg"
        >
          / {{ currentSelectedSalesOrg.sales_org.sales_org_name }}
        </ng-container>
      </div>
      <ds-page-actions>
        <ng-container *ngIf="showShopSelector$ | async as showShopSelector">
          <button
            mat-button
            *dsPageAction
            id="sparepartShopTypeChangeButton"
            [matMenuTriggerFor]="sparepartTypeMenu"
          >
            <mat-icon>store</mat-icon>
            {{ 'start_page.select_shop' | translate }}
            <mat-menu #sparepartTypeMenu="matMenu">
              <button mat-menu-item (click)="changeSparepartShop('SP')">
                <span> {{ 'start_page.sparepart_shop_SP' | translate }}</span>
              </button>
              <button mat-menu-item (click)="changeSparepartShop('PE')">
                <span> {{ 'start_page.sparepart_shop_PE' | translate }}</span>
              </button>
            </mat-menu>
          </button>
        </ng-container>
        <ng-container *ngIf="salesOrgs$ | async as salesOrgs">
          <ng-container *ngIf="salesOrgs.length">
            <button
              mat-button
              *dsPageAction
              id="salesOrgChangeButton"
              [matMenuTriggerFor]="salesOrgsMenu"
            >
              <mat-icon>switch_account</mat-icon>
              {{ 'start_page.switch_sales_org' | translate }}
              <mat-menu #salesOrgsMenu="matMenu">
                <button
                  mat-menu-item
                  (click)="changeSalesOrg(salesOrg.id)"
                  *ngFor="let salesOrg of salesOrgs"
                >
                  <span>{{ salesOrg.sales_org.sales_org_name }}</span>
                </button>
              </mat-menu>
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!checkoutView">
          <button
            mat-button
            *dsPageAction
            routerLink="./checkout"
            data-test="checkout-button"
            [dsButtonPending]="(totalCartItems$ | async) === null"
          >
            <mat-icon>shopping_cart</mat-icon>
            <span
              [matBadge]="totalCartItems$ | async"
              data-cy="totalCartItemsBadge"
              matBadgeOverlap="false"
              >{{ 'start_page.order' | translate }}</span
            >
          </button>
        </ng-container>
        <ng-container *ngIf="checkoutView">
          <button
            mat-button
            *dsPageAction
            (click)="print()"
            [dsButtonPending]="orderLoading$ | async"
          >
            <mat-icon>print</mat-icon>
            <span> {{ 'general.print' | translate }}</span
            >´
          </button>
          <button
            mat-button
            *dsPageAction
            [disabled]="
              (validOrderRequest$ | async) === false ||
              (orderLoading$ | async) ||
              (totalCartItems$ | async) === (null || 0)
            "
            (click)="submitOrder()"
            data-test="submit-order-button"
            [dsButtonPending]="orderLoading$ | async"
          >
            <mat-icon>credit_card</mat-icon>
            <span> {{ 'start_page.submit_order' | translate }}</span>
          </button>
        </ng-container>
      </ds-page-actions>
    </h1>
  </ds-page-sticky-header>
  <div fxFlex="0 1 auto" class="no-min-height">
    <router-outlet></router-outlet>
  </div>
</div>
