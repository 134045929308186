import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { APPS } from '@config';
import { UserService } from '@features/auth';
import { Store } from '@ngrx/store';
import { BusinessPartnerSalesOrg } from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PEC_PART_ROLE } from '../shared/models';
import { SparepartsShopState } from '../store';
import { CartActions, CartSelectors } from '../store/cart';
import { CheckOutActions, CheckOutSelectors } from '../store/checkout';
import { SalesOrgActions, SalesOrgSelectors } from '../store/salesorg';
import { OrderDialogComponent } from './order-dialog/order-dialog.component';
@Component({
  selector: 'sp-shop-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss'],
})
export class CreateOrderComponent implements OnInit {
  totalCartItems$: Observable<number | null>;
  salesOrgs$: Observable<BusinessPartnerSalesOrg[] | null>;
  currentSparepartType$: Observable<string>;
  showShopSelector$: Observable<boolean>;
  orderLoading$: Observable<boolean>;
  currentSelectedSalesOrg$: Observable<BusinessPartnerSalesOrg | undefined>;
  validOrderRequest$: Observable<boolean>;
  checkoutView = false;
  private readonly _destroy$ = new Subject<void>();

  constructor(
    private store: Store<SparepartsShopState>,
    private userService: UserService,
    private router: Router,
    private readonly _viewContainerRef: ViewContainerRef,
    private _dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this.router.events.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.checkoutView = this.router.url.includes('checkout');
    });
  }
  ngOnInit() {
    this.salesOrgs$ = this.store.select(
      SalesOrgSelectors.getSalesOrgsOfBusinessPartner,
    );
    const shop = this.route.snapshot.queryParamMap.get('shop');
    if (shop) {
      this.changeSparepartShop(shop);
    }

    this.showShopSelector$ = this.userService.currentUser.pipe(
      map((user) => {
        if (
          (user?.roles.includes(APPS.SPAREPART_INFO.ROLES.USER) ||
            user?.roles.includes(
              APPS.SPAREPART_INFO.ROLES.SERVICE_PARTNER_USER,
            )) &&
          user?.roles.includes(PEC_PART_ROLE)
        ) {
          return true;
        } else if (user?.roles.includes(PEC_PART_ROLE)) {
          this.changeSparepartShop('PE');
        }
        return false;
      }),
    );
    this.totalCartItems$ = this.store.select(CartSelectors.getTotalCartItems);
    this.validOrderRequest$ = this.store.select(
      CheckOutSelectors.getValidOrderRequest,
    );
    this.orderLoading$ = this.store.select(CheckOutSelectors.getOrderLoading);
    this.currentSelectedSalesOrg$ = this.store.select(
      CartSelectors.getCurrentSalesOrg,
    );
    this.currentSparepartType$ = this.store.select(
      CartSelectors.getCurrentSparepartType,
    );
    this.store.dispatch(CartActions.LoadShoppingCart());
    this.store.dispatch(SalesOrgActions.LoadSalesOrgs());
  }

  changeSalesOrg(id: number) {
    this.store.dispatch(
      CartActions.ChangeSalesOrgForCurrentCart({
        business_partner_sales_org_id: id,
      }),
    );
  }

  changeSparepartShop(type: string) {
    this.store.dispatch(
      CartActions.ChangeSparepartShop({
        sparepart_type: type,
      }),
    );
  }

  submitOrder() {
    this.store.dispatch(CheckOutActions.SubmitOrder());
    const config = new MatDialogConfig();
    config.viewContainerRef = this._viewContainerRef;
    this._dialog.open(OrderDialogComponent, config);
  }

  print() {
    window.print();
  }
}
