import { CommonModule, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Signal,
  computed,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FlexModule } from '@angular/flex-layout';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import { ApiCategory } from '@paldesk/shared-lib/data-access/palipedia-service-generated';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { AppWrapperV2Service } from '../../app-wrapper-v2.service';
import { DocumentType } from '../../model/document-type.model';
import { AppWrapperV2Store } from '../../signal-store/app-wrapper-v2-signal-store';
import { gAAppWrapperV2Constants } from '../model';

@Component({
  selector: 'ds-new-paldesk-documents-sidebar',
  templateUrl: './documents-sidebar.component.html',
  styleUrls: ['./documents-sidebar.component.scss'],
  standalone: true,
  imports: [
    DsSpacingModule,
    FlexModule,
    MatIconModule,
    NgIf,
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    GoogleAnalytics4DirectiveModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsSidebarComponent {
  @Input() public documentType!: DocumentType;

  filter = new FormControl<string>('');
  documentsFiltered: Signal<ApiCategory[] | null>;

  public googleAnalyticsConstants = gAAppWrapperV2Constants;

  private filterSignal: Signal<string | null | undefined>;

  constructor(
    private store: AppWrapperV2Store,
    public appWrapperService: AppWrapperV2Service,
  ) {
    this.filterSignal = toSignal(this.filter.valueChanges);

    this.documentsFiltered = computed(() => {
      const filterVal = this.filterSignal();
      if (filterVal) {
        return this.documentType === DocumentType.product
          ? this.store.filterProductDocuments(filterVal)
          : this.store.filterServiceDocuments(filterVal);
      }

      return this.documentType === DocumentType.product
        ? this.store.productCategories()
        : this.store.serviceDocumentCategories();
    });
  }
}
