import {
  SearchHistory,
  SearchResult,
  SearchResultLink,
  SearchScopes,
} from '@features/search';
import { createAction, props } from '@ngrx/store';

export namespace SearchActions {
  export const Search = createAction(
    '[Search] SEARCH',
    props<{ searchTerm: string; scope?: SearchScopes }>(),
  );
  export const SearchDoduments = createAction(
    '[Search] SEARCH_DOCUMENTS',
    props<{ searchTerm: string; documentClass?: string }>(),
  );
  export const Reset = createAction('[Search] RESET');
  // Results Actions
  export const SearchComplete = createAction(
    '[Search] SEARCH_COMPLETE',
    props<{ payload: SearchResult.Container }>(),
  );
  export const SearchDocumentsComplete = createAction(
    '[Search] SEARCH_DOCUMENTS_COMPLETE',
    props<{
      searchTerm: string;
      documentClass?: string;
    }>(),
  );

  export const LoadSearchHistory = createAction('[Search] LOAD_SEARCH_HISTORY');
  export const LoadSearchHistorySuccess = createAction(
    '[Search] LOAD_SEARCH_HISTORY_SUCCESS',
    props<{ payload: SearchHistory }>(),
  );
  export const LoadSearchHistoryError = createAction(
    '[Search] LOAD_SEARCH_HISTORY_ERROR',
  );

  export const AddSearchResult = createAction(
    '[Search] ADD_SEARCH_RESULT',
    props<{ key: string; result: SearchResultLink }>(),
  );
  export const AddSearchResultSuccess = createAction(
    '[Search] ADD_SEARCH_RESULT_SUCCESS',
    props<{ payload: SearchHistory }>(),
  );
  export const AddSearchResultError = createAction(
    '[Search] ADD_SEARCH_RESULT_ERROR',
  );

  export const RemoveSearchResult = createAction(
    '[Search] REMOVE_SEARCH_RESULT',
    props<{ key: string; result: SearchResultLink }>(),
  );
  export const RemoveSearchResultSuccess = createAction(
    '[Search] REMOVE_SEARCH_RESULT_SUCCESS',
    props<{ payload: SearchHistory }>(),
  );
  export const RemoveSearchResultError = createAction(
    '[Search] REMOVE_SEARCH_RESULT_ERROR',
  );

  export const ClearSearchHistory = createAction(
    '[Search] CLEAR_SEARCH_HISTORY',
    props<{ payload: string }>(),
  );
  export const ClearSearchHistorySuccess = createAction(
    '[Search] CLEAR_SEARCH_HISTORY_SUCCESS',
    props<{ payload: SearchHistory }>(),
  );
  export const ClearSearchHistoryError = createAction(
    '[Search] CLEAR_SEARCH_HISTORY_ERROR',
  );
}
