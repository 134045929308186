import {
  ContentChild,
  Directive,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { MatButton } from '@angular/material/button';

@Directive({
  selector: '[dsPageAction]',
})
export class DsPageActionDirective implements OnInit {
  @ContentChild(MatButton, { read: ElementRef }) set _element(
    value: ElementRef,
  ) {
    this.elementRef = value;
  }

  public elementRef: ElementRef;

  constructor(
    public templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
