import { Component, Inject, Input } from '@angular/core';
import {
  SearchIdentifiers,
  SearchRecentService,
  SearchResultLink,
} from '@features/search';
import { DS_APP_WRAPPER_CONFIG, DsAppWrapperConfig } from '../../config';

@Component({
  selector: 'ds-search-history',
  templateUrl: './search-history.component.html',
  styleUrls: ['./search-history.component.scss'],
})
export class SearchHistoryComponent {
  @Input() searchHistory: SearchResultLink[] = [];

  constructor(
    private searchRecentService: SearchRecentService,
    @Inject(DS_APP_WRAPPER_CONFIG) public config: DsAppWrapperConfig,
  ) {}

  removeResult(result: SearchResultLink) {
    this.searchRecentService
      .removeResult(SearchIdentifiers.Global, result)
      .subscribe({
        next: () =>
          (this.searchHistory = this.searchHistory.filter((x) => x !== result)),
      });
  }

  clearHistory() {
    this.searchRecentService.clearHistory(SearchIdentifiers.Global).subscribe({
      next: () => (this.searchHistory = []),
    });
  }
}
