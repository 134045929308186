<div fxLayout="row" fxLayoutGap="1rem" fxLayout.xs="column" fxLayoutGap.xs="0">
  <div fxFlex="50%" fxFlex.xs="auto">
    <mat-form-field class="full-width">
      <mat-label>{{
        'customer.industry_selection_segment' | translate
      }}</mat-label>
      <mat-select
        [formControl]="segmentControl"
        [required]="!isIndustryOptional"
      >
        <mat-option
          *ngFor="let segment of segments"
          [value]="segment.name"
          [disabled]="segment.disabled"
          >{{ segment.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFlex="50%" fxLayout="row" fxFlex.xs="auto">
    <div fxFlex>
      <mat-form-field class="full-width">
        <mat-label>{{
          'customer.industry_selection_market' | translate
        }}</mat-label>
        <mat-select
          [formControl]="marketControl"
          [required]="!isIndustryOptional"
        >
          <mat-option
            *ngFor="let market of markets"
            [value]="market.id"
            [disabled]="market.disabled"
            >{{ market.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <button
      mat-icon-button
      *ngIf="addable && marketControl.value"
      (click)="add.emit()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="!isTheOnlyRow || (isIndustryOptional && segmentControl.value)"
      (click)="delete.emit()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
