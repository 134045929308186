import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { RootEffects, rootReducer } from '@features/root-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerModule } from '@ui-kit/loading-spinner';
import { RemoveLeadingZerosPipeModule } from '@utils/remove-leading-zeros-pipe';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { CustomerFilterComponent } from './customer-filter/customer-filter.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerSelectionComponent } from './customer-selection/customer-selection.component';
import { CustomersComponent } from './customers/customers.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { IndustrySelectionComponent } from './industry-selection/industry-selection.component';
import { IndustryComponent } from './industry/industry.component';
import { CustomerEffects, customerReducer } from './shared';

@NgModule({
  declarations: [
    CustomerListComponent,
    CustomersComponent,
    CustomerFilterComponent,
    CustomerDetailsComponent,
    EditCustomerComponent,
    CustomerSelectionComponent,
    IndustrySelectionComponent,
    IndustryComponent,
  ],
  exports: [
    CustomerListComponent,
    CustomersComponent,
    CustomerFilterComponent,
    CustomerDetailsComponent,
    EditCustomerComponent,
    CustomerSelectionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    // Store
    StoreModule.forFeature('root', rootReducer),
    StoreModule.forFeature('customerModule', customerReducer),
    EffectsModule.forFeature([CustomerEffects, RootEffects]),
    ReactiveFormsModule,
    LoadingSpinnerModule,
    FlexModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatTableModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    RemoveLeadingZerosPipeModule,
  ],
})
export class SharedLibFeatSapCustomerModule {}
