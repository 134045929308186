import { createSelector } from '@ngrx/store';
import { CheckOutState } from '.';
import { SparepartsShopState } from '..';
import { CartState } from '../cart/cart.reducer';
import { CartSelectors } from '../cart/cart.selectors';

export namespace CheckOutSelectors {
  export const checkOutState = (state: SparepartsShopState) => state.checkOut;

  /**
   * Shipping conditions
   */
  export const getShippingConditions = createSelector(
    checkOutState,
    (state: CheckOutState) => state.shippingConditions,
  );
  export const getShippingConditionsLoading = createSelector(
    checkOutState,
    (state: CheckOutState) => state.shippingConditionsLoading,
  );
  export const getShippingConditionsLoadingError = createSelector(
    checkOutState,
    (state: CheckOutState) => state.shippingConditionsLoadingError,
  );
  /**
   * Shipping customer id
   */

  export const getShippingBusinessPartner = createSelector(
    checkOutState,
    (state: CheckOutState) => state.shippingBusinessPartner,
  );

  /**
   * Order request
   */

  export const getOrderRequest = createSelector(
    checkOutState,
    (state: CheckOutState) => state.orderRequest,
  );

  /**
   * Valid order request
   */

  export const getValidOrderRequest = createSelector(
    checkOutState,
    (state: CheckOutState) => state.validOrderRequest,
  );

  /**
   * Submit order
   */
  export const getOrderResponse = createSelector(
    checkOutState,
    (state: CheckOutState) => state.submitOrderResponse,
  );
  export const getOrderLoading = createSelector(
    checkOutState,
    (state: CheckOutState) => state.submitOrderLoading,
  );
  export const getSubmitOrderError = createSelector(
    checkOutState,
    (state: CheckOutState) => state.submitOrderError,
  );

  export const getCartTotal = createSelector(
    CartSelectors.cartState,
    (state: CartState) => state.cart?.total_cart_price,
  );
}
