import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule, Scroll } from '@angular/router';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsFeedbackFeatureModule } from '@design-system/feature/feedback';
import { DsPageModule } from '@design-system/feature/page';
import { HcStatusBannerModule } from '@features/hc-status-banner';
import { TranslateModule } from '@ngx-translate/core';
import { IsPdIconPipeModule } from '@shared-lib/is-pd-icon-pipe';
import { ConfirmPasswordModule } from '@ui-kit/form-control';
import { AppShortNamePipeModule } from '@utils/app-short-name-pipe';
import { filter } from 'rxjs';
import { AppWrapperComponent } from './app-wrapper.component';
import { SearchModule } from './search/search.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserComponent } from './user/user.component';
import { SharedWrapperModule } from './shared/shared-wrapper.module';
import { MenuComponent } from './menu/menu.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PortalModule,
    PdLetDirectiveModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatListModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    DsSpacingModule,
    SearchModule,
    TranslateModule,
    ConfirmPasswordModule,
    AppShortNamePipeModule,
    DsLoadingModule,
    DsPageModule,
    DsFeedbackFeatureModule,
    DsTextIndicatorModule,
    HcStatusBannerModule,
    MatMenuModule,
    MatTooltipModule,
    MatBadgeModule,
    MatRippleModule,
    IsPdIconPipeModule,
    DsEquipmentIconModule,
    SharedWrapperModule,
  ],
  declarations: [
    SidebarComponent,
    AppWrapperComponent,
    UserComponent,
    MenuComponent,
  ],
  exports: [AppWrapperComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    },
    MatDrawer,
  ],
})
export class DSAppWrapperModule {
  constructor(private router: Router) {
    this.router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .subscribe((e) => {
        if (!e.position) {
          document.getElementById('scrollToTopRestoration')?.scroll(0, 0);
        }
      });
  }

  static forRoot(config: Provider[]): ModuleWithProviders<DSAppWrapperModule> {
    return {
      ngModule: DSAppWrapperModule,
      providers: [...config],
    };
  }
}
