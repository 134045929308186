<form [formGroup]="checkOutForm">
  <div fxLayout="row wrap" [fxLayoutGap]="(1 | dsSpacing) + ' grid'">
    <div fxFlex="33.3%" fxFlex.lt-lg="50%">
      <h6>{{ 'checkout.shipping_adress' | translate }}</h6>
      <mat-card>
        <mat-card-content>
          <div class="padding">
            <paldesk-customer-details
              *ngIf="customerNumber$ | async as customerNumber"
              [customerNumber]="customerNumber"
              (customerDetailsLoaded)="setShippingBusinessPartner($event)"
              [isNewDesign]="true"
            >
            </paldesk-customer-details>
            <button
              (click)="openSelectShippingDialog()"
              mat-stroked-button
              class="margin-top print-hidden"
            >
              {{ 'checkout.edit' | translate }}
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div fxFlex="66.6%" fxFlex.lt-lg="50%">
      <h6>{{ 'checkout.shipping_details' | translate }}</h6>
      <mat-card fxLayout="row wrap" [fxLayoutGap]="1 | dsSpacing">
        <mat-card-content>
          <mat-form-field class="full-width">
            <input
              formControlName="deliveryDate"
              matInput
              readonly
              [min]="currentDate"
              [matDatepicker]="picker"
              placeholder="{{ 'checkout.wish_delivery_date' | translate }}"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="!checkOutForm.controls['deliveryDate'].valid"
              >{{ 'general.required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>{{ 'checkout.order_number' | translate }}</mat-label>
            <input
              matInput
              data-cy="matNr"
              autocomplete="off"
              formControlName="orderNumber"
            />
            <mat-error
              *ngIf="checkOutForm.controls['orderNumber']?.hasError('required')"
              >{{ 'general.required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                checkOutForm.controls['orderNumber']?.hasError('maxlength')
              "
            >
              {{
                'form_char_restriction'
                  | translate
                    : {
                        limit: 20,
                        count:
                          checkOutForm.controls['orderNumber'].value.length - 20
                      }
              }}
            </mat-error>
            <mat-hint align="end">
              {{ checkOutForm.controls['orderNumber'].value.length }} / 20
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>{{ 'checkout.shipping_terms' | translate }}</mat-label>
            <mat-select
              name="shippingConditions"
              formControlName="shippingConditions"
            >
              <mat-optgroup
                *ngFor="let condition of shippingConditions$ | async"
                [label]="condition.group"
              >
                <mat-option
                  *ngFor="let item of condition.shipping_option_items"
                  [value]="item.sap_code"
                >
                  {{ item.option }}
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error
              *ngIf="!checkOutForm.controls['shippingConditions'].valid"
              >{{ 'general.required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label
              >{{ 'checkout.comment' | translate }} ({{
                'general.optional' | translate
              }})</mat-label
            >
            <textarea
              matInput
              autocomplete="off"
              formControlName="comment"
            ></textarea>
            <mat-error>
              {{
                'form_char_restriction'
                  | translate
                    : {
                        limit: 150,
                        count:
                          checkOutForm.controls['comment'].value.length - 150
                      }
              }}
            </mat-error>
            <mat-hint align="end">
              {{ checkOutForm.controls['comment'].value.length }} / 150
            </mat-hint>
          </mat-form-field>
          <div fxLayout="row wrap" [fxLayoutGap]="1 | dsSpacing">
            <mat-checkbox formControlName="completeDelivery">{{
              'checkout.complete_delivery' | translate
            }}</mat-checkbox>
            <mat-checkbox
              *ngIf="weeklyOrderVisible$ | async"
              id="weeklyOrder"
              name="weeklyOrder"
              formControlName="weeklyOrder"
              >{{ 'checkout.weekly_order' | translate }}</mat-checkbox
            >
          </div>
          <ng-container *ngIf="isNorthAmerica$ | async">
            <mat-form-field class="full-width">
              <mat-label>{{ 'checkout.order_type' | translate }}</mat-label>
              <mat-select formControlName="orderType">
                <mat-option value="Daily">Daily</mat-option>
                <mat-option value="Unit Down">Unit Down</mat-option>
                <mat-option value="Stock">Stock</mat-option>
              </mat-select>
              <mat-error *ngIf="!checkOutForm.controls['orderType'].valid"
                >{{ 'general.required' | translate }}
              </mat-error>
            </mat-form-field>
            <div fxLayout="row wrap" [fxLayoutGap]="1 | dsSpacing">
              <mat-checkbox formControlName="shipFromWch">{{
                'checkout.ship_from_wch' | translate
              }}</mat-checkbox>
              <mat-checkbox formControlName="includesTmfItems">{{
                'checkout.tmf_items' | translate
              }}</mat-checkbox>
              <mat-checkbox formControlName="shipCollect">{{
                'checkout.ship_collect' | translate
              }}</mat-checkbox>
            </div>
            <mat-form-field
              class="full-width"
              *ngIf="checkOutForm.controls['shipCollect']?.value"
            >
              <mat-label>{{
                'checkout.ship_collect_account_number' | translate
              }}</mat-label>
              <input
                matInput
                data-cy="ship_collect_account_number"
                formControlName="shipCollectAccountNumber"
              />
              <mat-error>
                {{
                  'form_char_restriction'
                    | translate
                      : {
                          limit: 20,
                          count:
                            checkOutForm.controls['shipCollectAccountNumber']
                              .value.length - 20
                        }
                }}
              </mat-error>
              <mat-hint align="end">
                {{
                  checkOutForm.controls['shipCollectAccountNumber'].value.length
                }}
                / 20
              </mat-hint>
              <mat-error
                *ngIf="
                  !checkOutForm.controls['shipCollectAccountNumber'].hasError(
                    'required'
                  )
                "
                >{{ 'general.required' | translate }}
              </mat-error>
            </mat-form-field>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
