import { RootEffects, rootReducer, RootState } from '@features/root-store';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap } from '@ngrx/store';
import { CartEffects, cartReducer, CartState } from './cart';
import { CheckOutEffects, checkOutReducer, CheckOutState } from './checkout';
import {
  OrderHistoryEffects,
  orderHistoryReducer,
  OrderHistoryState,
} from './order-history';
import { SalesOrgEffects, salesOrgReducer, SalesOrgState } from './salesorg';
import { SearchEffects, searchReducer, SearchState } from './search';

export interface SparepartsShopState {
  // RootState needed for CustomersComponent
  root: RootState;
  cart: CartState;
  search: SearchState;
  checkOut: CheckOutState;
  salesOrg: SalesOrgState;
  orderHistory: OrderHistoryState;
}

export const sparepartsShopReducers: ActionReducerMap<
  SparepartsShopState,
  any
> = {
  root: rootReducer,
  cart: cartReducer,
  search: searchReducer,
  checkOut: checkOutReducer,
  salesOrg: salesOrgReducer,
  orderHistory: orderHistoryReducer,
};

export const sparepartsShopEffects = [
  EffectsModule.forFeature([
    CartEffects,
    SearchEffects,
    CheckOutEffects,
    SalesOrgEffects,
    RootEffects,
    OrderHistoryEffects,
  ]),
];
