import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { RocketComponent } from './rocket/rocket.component';
import { SantaComponent } from './santa/santa.component';
import { spotlightReducer } from './shared/spotlight.reducer';
import { DsSpotlightComponent } from './spotlight.component';
import { DsSpotlightDirective } from './spotlight.directive';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    FlexLayoutModule,
    TranslateModule,
    DsSpacingModule,
    StoreModule.forFeature('spotlightModule', spotlightReducer, {}),
  ],
  declarations: [
    DsSpotlightComponent,
    DsSpotlightDirective,
    RocketComponent,
    SantaComponent,
  ],
  exports: [DsSpotlightComponent, DsSpotlightDirective],
})
export class DsSpotlightModule {}
