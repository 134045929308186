<div fxLayout="column" class="full-height">
  <mat-card>
    <mat-card-content>
      <form
        [formGroup]="searchForm"
        (ngSubmit)="submitSearchForm()"
        fxLayout="row"
        fxLayoutAlign="stretch baseline"
        [fxLayoutGap]="1 | dsSpacing"
        fxLayout.xs="column"
        fxLayoutAlign.xs="start"
        fxLayoutGap.xs="0"
      >
        <mat-form-field fxFlex="80" fxFlex.xs="100" class="no-form-padding">
          <mat-label>{{ 'start_page.search_term' | translate }}</mat-label>
          <input matInput formControlName="searchTerm" data-cy="searchTerm" />
        </mat-form-field>
        <button
          fxFlex="20"
          fxFlex.xs="100"
          *pdLet="searchForm?.controls as controls"
          mat-raised-button
          id="submitSearch"
          color="accent"
          type="submit"
          data-cy="submit"
          [disabled]="controls !== null && !controls['searchTerm']?.valid"
          [dsButtonPending]="sparepartResultsLoading$ | async"
        >
          {{ 'start_page.search' | translate }}
        </button>
      </form>
    </mat-card-content></mat-card
  >

  <div
    class="errors"
    *ngIf="
      (sparepartResultsLoadingError$ | async) &&
      (sparepartResultsLoadingErrorMsg$ | async) as searchErrorMsg
    "
  >
    <div *ngIf="searchErrorMsg.validations?.length; else contactSupport">
      <ds-text-indicator [type]="'error'">{{
        'start_page.error_occured' | translate
      }}</ds-text-indicator>
      <p *ngFor="let validationErr of searchErrorMsg.validations">
        {{ validationErr }}
      </p>
    </div>
    <ng-template #contactSupport>
      <ds-text-indicator [type]="'error'">{{
        'start_page.error_occured' | translate
      }}</ds-text-indicator>
      <ng-container
        *ngIf="searchErrorMsg.code !== 'error'; else unexpectedError"
      >
        <p *ngIf="searchErrorMsg.message">{{ searchErrorMsg.message }}</p>
      </ng-container>
      <p>
        {{ 'general.error_code.contact_support' | translate }}
        <a
          href="mailto:paldesk@palfinger.com?subject=End-customer registration error&body=LogId: {{
            searchErrorMsg.log_id
          }} Message: {{ searchErrorMsg.message }}"
          >paldesk&#64;palfinger.com</a
        >
      </p>

      <ng-template #unexpectedError>
        <span *ngIf="searchErrorMsg.code === 'error'"
          >{{ 'general.error_code.contact_support' | translate }}
          <a
            href="mailto:paldesk@palfinger.com?subject=End-customer registration error&body=LogId: {{
              searchErrorMsg.log_id
            }}"
            >paldesk&#64;palfinger.com</a
          ></span
        >
      </ng-template>
    </ng-template>
  </div>
  <br />
  <ng-container
    *ngIf="(sparepartResults$ | async) || (sparepartResultsLoading$ | async)"
  >
    <sp-shop-sparepart-result-table
      fxFlex="1 1 auto"
      fxLayout="column"
      class="no-min-height"
      (changePage)="submitSearchForm($event)"
    ></sp-shop-sparepart-result-table>
  </ng-container>
</div>
