import { Component, Input } from '@angular/core';
import { DsTextIndicatorType } from '@design-system/components/text-indicator';

@Component({
  selector: 'ds-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class DsBannerComponent {
  @Input() type: DsTextIndicatorType = 'info';
}
