import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, Inject, OnInit, Optional } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { PollingUtils } from '@shared-lib/rxjs';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { HcStatusBannerStore } from './hc-status-banner.store';
import {
  HEALTH_CHECK_OPTIONS_TOKEN,
  HealthCheckBannerOptions,
} from './hc-status-config';
import { ServiceStatusResponse } from './models';

@Component({
  selector: 'paldesk-hc-status-banner',
  templateUrl: './hc-status-banner.component.html',
  styleUrls: ['./hc-status-banner.component.scss'],
})
export class HcStatusBannerComponent implements OnInit {
  showBanner$ = of(false);
  isHideBannerEnabled$: BehaviorSubject<boolean>;
  statusPaldeskPageLink: string;

  private readonly PaldesCoreHcApp = 'PALDESK Core';
  constructor(
    private httpClient: HttpClient,
    @Optional()
    @Inject(HEALTH_CHECK_OPTIONS_TOKEN)
    private config: HealthCheckBannerOptions,
    private messengerService: MessengerService,
    private store: HcStatusBannerStore,
    private _destroyRef: DestroyRef,
  ) {
    this.isHideBannerEnabled$ = new BehaviorSubject<boolean>(
      sessionStorage.getItem('paldesk_banner_hidden') === 'true',
    );
  }

  ngOnInit(): void {
    if (this.config?.statusPaldeskApiUri) {
      this.statusPaldeskPageLink = this.config.statusPaldeskPageUri;
      const hcCheckRequest$ = this.httpClient
        .post<ServiceStatusResponse[]>(this.config.statusPaldeskApiUri, {
          serviceNames: [...this.config.productsToCheck, this.PaldesCoreHcApp],
        })
        .pipe(take(1));

      this.showBanner$ = this.isHideBannerEnabled$.pipe(
        switchMap((isHideBannerEnabled) => {
          if (isHideBannerEnabled) {
            return of(false);
          } else {
            return PollingUtils.polling(hcCheckRequest$, {
              interval: 300000,
            }).pipe(
              map((checks) => checks?.some((x) => x.status !== 'Healthy')),
              tap((value) =>
                value ? this.store.showBanner() : this.store.hideBanner(),
              ),
            );
          }
        }),
        takeUntilDestroyed(this._destroyRef),
      );
    } else {
      this.messengerService.sendMessage(
        MessageSeverityType.error,
        'Health Check Banner component not setup',
        MessageTargetType.console,
      );
    }
  }

  hideBanner(): void {
    this.isHideBannerEnabled$.next(true);
    sessionStorage.setItem('paldesk_banner_hidden', 'true');
    this.store.hideBanner();
  }
}
