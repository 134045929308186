import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SanitizeService {
  constructor(private sanitizer: DomSanitizer) {}

  sanitizeSearchTerm(value: string | null): string | undefined {
    return value
      ? this.sanitizer.sanitize(SecurityContext.HTML, value ?? '') ?? undefined
      : undefined;
  }

  sanitizeFilter(values: string[] | null): string[] {
    if (!values) {
      return [];
    }
    return values.map((value) => {
      const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, value);
      return sanitized !== null ? sanitized : '';
    });
  }
}
