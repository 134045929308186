<div
  fxLayout="column"
  fxLayoutAlign="space-between center"
  [fxLayoutGap]="1 | dsSpacing"
  class="full-height full-width"
>
  <div
    *ngIf="
      userService?.isAuthorized && userService?.userContext;
      else loginAction
    "
    class="full-width"
  >
    <div class="title">
      <h2 class="no-margin" data-cy="user-headline">
        {{ userService.userContext.firstname }}
        {{ userService.userContext.lastname }}<br />
        <small>{{ userService.userContext.company_name }}</small>
      </h2>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <a data-cy="user-profile-button" mat-button (click)="openUserProfile()">
        <mat-icon>account_circle</mat-icon>
        {{ 'general.profile' | translate }}
      </a>
      <a
        mat-button
        [href]="config.paldeskBasePath + '/pat'"
        target="_blank"
        data-cy="user-security-button"
      >
        <mat-icon>build</mat-icon>
        {{ 'general.security' | translate }}
      </a>
      <a mat-button data-cy="reset-spotlight-button" (click)="resetSpotlight()">
        <mat-icon ds-page-sidebar-item-icon> flare </mat-icon>
        {{ 'reset_spotlight' | translate }}
      </a>
      <button mat-button (click)="logout()" data-cy="user-logout-button">
        <mat-icon>power_settings_new</mat-icon>
        {{ 'general.sign_out' | translate }}
      </button>
    </div>
  </div>
  <ng-template #loginAction>
    <div class="full-width">
      <button mat-button (click)="login()" data-cy="login-button">
        <mat-icon>vpn_key</mat-icon>
        {{ 'general.sign_in' | translate }}
      </button>
    </div>
  </ng-template>
  <div class="full-width">
    <a
      *ngIf="config.paldeskDataProtectionRegulationUrl"
      mat-button
      [href]="config.paldeskDataProtectionRegulationUrl"
      data-cy="data-protection-button"
    >
      <mat-icon>privacy_tip</mat-icon>
      {{ 'footer.data_protection' | translate }}
    </a>
    <a
      mat-button
      href="https://vdp.palfinger.com/"
      data-cy="vulnerability-policy-button"
    >
      <mat-icon>policy</mat-icon>
      {{ 'general.vulnerability_policy' | translate }}
    </a>
    <a
      data-cy="user-help-support-button"
      mat-button
      href="mailto:paldesk@palfinger.com?subject=Support for PALDESK Issue"
    >
      <mat-icon>help_center</mat-icon>
      {{ 'footer.support_button_text' | translate }}
    </a>
    <a mat-button [href]="paldeskSupportPath" data-cy="user-status-button">
      <mat-icon>traffic</mat-icon>
      PALDESK Status
    </a>
    <span class="mat-list-mock mdc-button" data-cy="user-copyright"
      ><mat-icon>copyright</mat-icon> PALFINGER AG {{ copyrightYear }}</span
    >
  </div>
</div>
