import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  QueryParamsHandling,
  Router,
} from '@angular/router';
import { Observable, Subject, filter, map, startWith, takeUntil } from 'rxjs';
import {
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
} from '@design-system/feature/app-wrapper';
import { dsAnimations } from '@design-system/cdk/animations';
import { UserService } from '@features/auth';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';

export interface Menu {
  title: string;
  titleIcon?: string;
  equipmentIcon?: string;
  navigation: MenuItem[];
  backButtonUrl?: string;
}

export interface MenuItem {
  title: string;
  icon?: string;
  equipmentIcon?: string;
  externalLink?: string;
  routerLink?: string;
  children?: MenuItem[];
  badge?: string | number;
  isBadgeIcon?: boolean;
  queryParamsHandling?: QueryParamsHandling;
  onClick?: () => void;
  isHighlighted?: boolean;
  queryParams?: Params;
}
export const clickEvent = new Event('selectSiderbarNavigation');
@Component({
  selector: 'ds-menu-v2',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('slideUpDown', [
      transition(':enter', [
        style({ height: 0 }),
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ]),
      transition(':leave', [
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: 0 })),
      ]),
    ]),
    dsAnimations.rotate90,
    dsAnimations.rotate180,
  ],
})
export class MenuComponent implements OnDestroy {
  @Input() isExpanded: boolean;
  @Input() applicationMenu: Menu;
  @Input() menuLoadingCount: number;

  @Output() helpMenuBackNavigation = new EventEmitter();

  paldeskUrl = 'https://paldesk.palfinger.com';

  hideMenu$: Observable<boolean>;
  gtMdObserver$: boolean;

  displayConditionMet: boolean;

  private opened: MenuItem[] = [];
  private readonly destroy$ = new Subject<void>();

  constructor(
    public media: MediaObserver,
    @Inject(DS_APP_WRAPPER_CONFIG) config: DsAppWrapperConfig,
    public userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private destroyRef: DestroyRef,
    private changeDetectorRef: ChangeDetectorRef,
    private _breakpointObserver: BreakpointObserver,
  ) {
    this._breakpointObserver
      .observe([Breakpoints.XLarge, Breakpoints.Large])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.gtMdObserver$ = res.matches;
      });
    media
      .asObservable()
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe(() => {
        this.displayConditionMet =
          userService.userContext?.partnertype !== 5
            ? !media.isActive(['xs', 'md', 'sm'])
            : !media.isActive(['xs', 'sm']);
        changeDetectorRef.detectChanges();
      });
    if (config.paldeskBasePath) {
      this.paldeskUrl = config.paldeskBasePath;
    }
    this.hideMenu$ = router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute.snapshot),
      map((rt) => {
        while (rt.firstChild) {
          rt = rt.firstChild;
        }
        return rt.data;
      }),
      map((data) => !!data?.hideMenu),
      startWith(false),
      takeUntil(this.destroy$),
    );
  }

  toggleOpened(item: MenuItem): void {
    if (this.opened.includes(item)) {
      this.opened = this.opened.filter((x) => x !== item);
    } else {
      this.opened.push(item);
    }
  }

  openItem($event: boolean, item: MenuItem): void {
    if ($event && !this.opened.includes(item)) {
      this.opened.push(item);
    }
  }

  isOpened(item: MenuItem): boolean {
    return this.opened.includes(item);
  }

  onItemClick(item?: MenuItem, backButtonUrl?: string) {
    if (backButtonUrl === '/help') {
      this.helpMenuBackNavigation.emit();
    }

    if (item?.onClick) {
      item.onClick();
    }
    document.dispatchEvent(clickEvent);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
