import { createSelector } from '@ngrx/store';
import { SearchState } from '.';
import { SparepartsShopState } from '..';

export namespace SearchSelectors {
  export const searchState = (state: SparepartsShopState) => state.search;

  /**
   * Sparepart
   */
  export const getSparepartResults = createSelector(
    searchState,
    (state: SearchState) => state.sparepartResults,
  );
  export const getSparepartResultsLoading = createSelector(
    searchState,
    (state: SearchState) => state.sparepartResultsLoading,
  );
  export const getSparepartResultsLoadingError = createSelector(
    searchState,
    (state: SearchState) => state.sparepartResultsLoadingError,
  );
  export const getSparepartResultsLoadingErrorMsg = createSelector(
    searchState,
    (state: SearchState) => state.sparepartResultsLoadingErrorMsg,
  );

  /**
   * BOM
   */
  export const getBOM = createSelector(
    searchState,
    (state: SearchState) => state.bomResults,
  );
  export const getBOMLoading = createSelector(
    searchState,
    (state: SearchState) => state.bomLoading,
  );
  export const getBOMLoadingError = createSelector(
    searchState,
    (state: SearchState) => state.bomLoadingError,
  );
}
