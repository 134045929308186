import { SearchHistory, SearchResult, SearchScopes } from '@features/search';
import { Action, createReducer, on } from '@ngrx/store';
import { SearchActions } from './search.actions';

export interface SearchState {
  searchResult: SearchResult.Container;
  lastSearchResult?: SearchResult.Container;
  searchTerm: string;
  scope?: SearchScopes;
  searchHistory: SearchHistory | null;
  searchPending: boolean;
}

export const initialState: SearchState = {
  searchResult: <SearchResult.Container>{},
  lastSearchResult: <SearchResult.Container>{},
  searchTerm: '',
  scope: undefined,
  searchHistory: null,
  searchPending: false,
};

const reducer = createReducer(
  initialState,
  on(SearchActions.Search, (state, { searchTerm, scope }) => ({
    ...state,
    searchTerm: searchTerm,
    scope: scope,
    searchPending: true,
  })),
  on(SearchActions.SearchComplete, (state, { payload }) => ({
    ...state,
    searchPending: false,
    searchResult: payload,
    lastSearchResult: payload,
  })),
  on(SearchActions.Reset, (state) => ({
    ...state,
    searchPending: false,
    searchResult: <SearchResult.Container>{},
    searchTerm: '',
  })),
  on(
    SearchActions.AddSearchResultSuccess,
    SearchActions.RemoveSearchResultSuccess,
    SearchActions.LoadSearchHistorySuccess,
    SearchActions.ClearSearchHistorySuccess,
    (state, { payload }) => ({
      ...state,
      searchHistory: payload,
    }),
  ),
  on(
    SearchActions.LoadSearchHistoryError,
    SearchActions.AddSearchResultError,
    SearchActions.RemoveSearchResultError,
    SearchActions.ClearSearchHistoryError,
    (state) => ({
      ...state,
      searchHistory: null,
    }),
  ),
);

export function searchReducer(state: SearchState | undefined, action: Action) {
  return reducer(state, action);
}
