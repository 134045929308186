import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SearchResult } from '@features/search';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DS_APP_WRAPPER_CONFIG, DsAppWrapperConfig } from '../../config';

@Injectable()
export class SearchService {
  constructor(
    private http: HttpClient,
    @Inject(DS_APP_WRAPPER_CONFIG) private config: DsAppWrapperConfig,
  ) {}
  /**
      * Retrieves search result
      * @param searchTerm
        @param scope equipment, product, document, sparepart, application
      */

  search(
    searchTerm: string,
    scope?: string,
  ): Observable<SearchResult.Container> {
    let params = new HttpParams().set('search_term', searchTerm.trim());
    if (scope) {
      params = params.set('scope', scope);
    }
    const path = this.config.appGatewayBasePath
      ? `${this.config.appGatewayBasePath}/search/search`
      : `${this.config.apiBasePath}/search/v1/search`;
    return this.http
      .get<SearchResult.Container>(path, { params })
      .pipe(tap((result) => this.applyEmptyFlag(result)));
  }

  private applyEmptyFlag(data: SearchResult.Container) {
    if (
      (data.products && data.products.amount > 0) ||
      (data.equipments && data.equipments.length > 0) ||
      (data.spareparts && data.spareparts.length > 0) ||
      (data.applications && data.applications.length > 0) ||
      (data.documents && data.documents.amount > 0)
    ) {
      data.is_empty = false;
    } else {
      data.is_empty = true;
    }
  }
}
