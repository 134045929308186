import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import { DS_APP_WRAPPER_CONFIG } from '@design-system/feature/app-wrapper';
import {
  AppWrapperV2Module,
  HEALTH_CHECK_OPTIONS_TOKEN,
  HealthCheckBannerOptions,
} from '@design-system/feature/app-wrapper-v2';
import { DsPageModule } from '@design-system/feature/page';
import { DsSnackbarModule } from '@design-system/feature/snackbar';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@paldesk/shared-lib/data-access/identity-service-generated';
import { BASE_PATH } from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { CreateOrderModule } from './create-order/create-order.module';
import { sparepartsShopEffects, sparepartsShopReducers } from './store';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedLibFeatTranslationModule,
    BrowserAnimationsModule,
    AppWrapperV2Module.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: () => ({
          apiBasePath: environment.palfinger_api_management,
          isNativeApp: false,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          appInsightsRole: 'FE: Sparepart Shop',
          appGatewayBasePath: environment.palfinger_app_gateway,
          appName: 'Sparepart Shop',
          productScreenBasePath: environment.LINKS.PRODUCT_SCREEN,
        }),
      },
    ]),
    CommonModule,
    CreateOrderModule,
    MatIconModule,
    DsSnackbarModule,
    FlexLayoutModule,
    DsPageModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot(),

    StoreModule.forRoot(sparepartsShopReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    sparepartsShopEffects,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
  ],
  providers: [
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
    // Service Base path
    {
      provide: BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/spareparts-shop',
    },
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    // HealthCheck Status Banner
    {
      provide: HEALTH_CHECK_OPTIONS_TOKEN,
      useFactory: (): HealthCheckBannerOptions => ({
        productsToCheck: ['Sparepart Shop'],
        statusPaldeskApiUri:
          environment.palfinger_app_gateway + '/status/api/v1/health',
        statusPaldeskPageUri: environment.LINKS.STATUS_PALDESK_PAGE,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
