<ng-container *ngIf="customer$ | async as customer; else loading">
  <ng-container *ngIf="!isNewDesign; else newDesign">
    <strong> {{ customer.company_name }}</strong>
    <span *ngIf="customer.company_name2">({{ customer.company_name2 }})</span>
    <br />
    <ng-container
      >{{ customer.street_name
      }}<span *ngIf="customer.house_number">, {{ customer.house_number }}</span
      ><br />
    </ng-container>
    <ng-container
      >{{ customer.postal_code }} {{ customer.city }} <br />
    </ng-container>
    <ng-container>
      <span *ngIf="customer.region_code">({{ customer.region_code }})</span>
      {{ customer.country_code }} <br />
    </ng-container>
    <ng-container *ngIf="customer.phone_number"
      >{{ 'customer.phone' | translate }}: {{ customer.phone_number }} <br />
    </ng-container>
    <ng-container *ngIf="customer.mobile_phone_number"
      >{{ 'customer.mobile' | translate }}:
      {{ customer.mobile_phone_number }}
      <br />
    </ng-container>
    <ng-container *ngIf="customer.fax"
      >{{ 'customer.fax' | translate }}: {{ customer.fax }} <br />
    </ng-container>
    <ng-container *ngIf="customer.email"
      >{{ 'customer.email' | translate }}: {{ customer.email }}
      <br />
    </ng-container>
    <ng-container *ngIf="customer.url"
      >{{ 'customer.website' | translate }}: {{ customer.url }}
      <br />
    </ng-container>
  </ng-container>

  <ng-template #newDesign>
    <table list>
      <span
        >{{ customer.company_name }}
        <span *ngIf="customer.company_name2"
          >({{ customer.company_name2 }})</span
        ></span
      >
      <tr>
        <td>{{ 'customer.street' | translate }}:</td>
        <td>
          {{ customer.street_name
          }}<span *ngIf="customer.house_number"
            >, {{ customer.house_number }}</span
          >
        </td>
      </tr>
      <tr>
        <td>{{ 'customer.city' | translate }}:</td>
        <td>{{ customer.postal_code }} {{ customer.city }}</td>
      </tr>
      <tr>
        <td>{{ 'customer.country' | translate }}:</td>
        <td>
          <span *ngIf="customer.region_code">({{ customer.region_code }})</span>
          {{ customer.country_code }}
        </td>
      </tr>
      <tr *ngIf="customer.phone_number">
        <td>{{ 'customer.phone' | translate }}:</td>
        <td>{{ customer.phone_number }}</td>
      </tr>
      <tr *ngIf="customer.mobile_phone_number">
        <td>{{ 'customer.mobile' | translate }}:</td>
        <td>
          {{ customer.mobile_phone_number }}
        </td>
      </tr>
      <tr *ngIf="customer.fax">
        <td>{{ 'customer.fax' | translate }}:</td>
        <td>{{ customer.fax }}</td>
      </tr>
      <tr *ngIf="customer.email">
        <td>{{ 'customer.email' | translate }}:</td>
        <td>{{ customer.email }}</td>
      </tr>
      <tr *ngIf="customer.url">
        <td>{{ 'customer.website' | translate }}:</td>
        <td>{{ customer.url }}</td>
      </tr>
    </table>
  </ng-template>
</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
